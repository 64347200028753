import React, { CSSProperties } from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline';
import classnames from 'classnames';
import { useCart } from '@Provider/frontastic';
import { useCheckout } from '@Provider/revelo-checkout';
import Spinner from 'components/commercetools-ui/spinner';
import { useFormat } from 'helpers/hooks/useFormat';
import OrderSummaryProps from './interfaces/OrderSummaryProps';
import SummaryLineItem from './line-item';
import Price from '../../../../commercetools-ui/price';
import TruckIcon from '../../../../icons/truck';
import DiscountField from '../../../discount/discount-field';
import { CartSummaryHandler } from '../../utils/CartSummaryHandler';

const MobileOrderSummary = ({ orderTotals, maxVoucherLimit, deliveryTime }: OrderSummaryProps) => {
  //i18n messages
  const { formatMessage } = useFormat({ name: 'common' });
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });

  const { data: cart } = useCart();
  const { deliveryCountry } = useCheckout();

  return (
    <section aria-labelledby="order-heading" className="sticky top-0 -mx-4 mb-10 bg-neutral-50 shadow lg:hidden">
      <Disclosure>
        {({ open }) => (
          <div className="mx-auto max-w-lg">
            <div>
              <Disclosure.Button
                className="flex w-full items-center justify-between px-4 py-5 font-medium"
                style={{ '-webkit-tap-highlight-color': 'transparent' } as CSSProperties}
              >
                <span id="order-heading" className="text-left text-lg font-bold">
                  {formatCheckoutMessage({ id: 'yourOrder', defaultMessage: 'Your Order' })}
                </span>
                <span className="flex items-center justify-end gap-2">
                  <span className="text-sm font-normal">
                    {formatCheckoutMessage({ id: 'total', defaultMessage: 'Total' })}:&nbsp;
                    <Price
                      className="text-sm font-bold"
                      price={orderTotals.orderTotal || CartSummaryHandler.calculateOrderTotal(cart, orderTotals)}
                    />
                  </span>
                  {open ? (
                    <ChevronUpIcon className="block size-7.5 text-neutral-950" />
                  ) : (
                    <ChevronDownIcon className="block size-7.5 text-neutral-950" />
                  )}
                </span>
              </Disclosure.Button>

              <Disclosure.Panel className="px-4">
                <ul role="list" className="space-y-1.5">
                  {cart.lineItems.map((lineItem, i) => (
                    <li key={i} className="flex space-x-6">
                      <SummaryLineItem lineItem={lineItem} />
                    </li>
                  ))}
                </ul>

                <DiscountField name="discountCodeMobile" summaryType="pills" maxVoucherLimit={maxVoucherLimit} />

                <dl className="mt-4 space-y-2.5 border-t border-neutral-200 pt-6 text-sm font-normal">
                  {!orderTotals.shippingTotal ? (
                    <div className="flex justify-between">
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      {orderTotals.subTotal && (
                        <div className="flex justify-between">
                          <dt>{formatCheckoutMessage({ id: 'subtotal', defaultMessage: 'Subtotal' })}</dt>
                          <dd>
                            <Price price={orderTotals.subTotal} className="font-semibold" />
                          </dd>
                        </div>
                      )}
                      {orderTotals.discountTotal && orderTotals.discountTotal.centAmount !== 0 && (
                        <div className="flex justify-between">
                          <dt className="flex">
                            {formatCartMessage({ id: 'discounts', defaultMessage: 'Discounts' })}
                          </dt>
                          <dd>
                            <Price price={orderTotals.discountTotal} className="font-semibold" />
                          </dd>
                        </div>
                      )}
                      {orderTotals.shippingTotal && (
                        <div className="flex justify-between">
                          <dt>
                            <span>
                              {formatCheckoutMessage({
                                id: 'shippingToCountry',
                                defaultMessage: `Shipping to ${deliveryCountry}`,
                                values: {
                                  country: formatMessage({
                                    id: `country.${deliveryCountry}`,
                                    defaultMessage: deliveryCountry,
                                  }),
                                },
                              })}
                            </span>
                            <span className="mt-4 flex items-center gap-2.5 text-sm text-neutral-950">
                              <TruckIcon className="size-6 shrink-0" aria-hidden="true" />
                              {deliveryTime}
                            </span>
                          </dt>
                          <dd>
                            <Price
                              price={orderTotals.shippingTotal}
                              className={classnames('font-semibold', {
                                "after:content-['*']": orderTotals.shippingTotal.centAmount > 0,
                              })}
                            />
                          </dd>
                        </div>
                      )}
                    </>
                  )}
                </dl>

                <p className="mt-6 flex items-center justify-between border-t border-neutral-200 py-6 text-sm font-bold">
                  <span className="text-base font-bold">
                    {formatCheckoutMessage({ id: 'total', defaultMessage: 'Total' })}
                    <span className="text-sm font-light">
                      &nbsp;{formatCheckoutMessage({ id: 'inclVat', defaultMessage: '(inkl. Mwst)' })}
                    </span>
                  </span>
                  <Price
                    price={orderTotals.orderTotal || CartSummaryHandler.calculateOrderTotal(cart, orderTotals)}
                    className="text-base font-bold"
                  />
                </p>
              </Disclosure.Panel>
            </div>
          </div>
        )}
      </Disclosure>
    </section>
  );
};

export default MobileOrderSummary;
