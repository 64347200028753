import React, { useCallback, useState } from 'react';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import { useFormat } from 'helpers/hooks/useFormat';
import Redirect from 'helpers/redirect';
import { Reference, ReferenceLink } from 'helpers/reference';
import { useAccount } from 'frontastic';
import Image, { NextFrontasticImage } from 'frontastic/lib/image';
import Button from '../../../revelo-ui/content/button';

export interface LoginProps {
  registerLink?: Reference;
  accountLink?: Reference;
}

const Login: React.FC<LoginProps> = ({ registerLink, accountLink }) => {
  //i18n messages
  const { formatMessage: formatErrorMessage } = useFormat({ name: 'error' });
  const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
  const { formatMessage } = useFormat({ name: 'common' });

  //account actions
  const { login, loggedIn, requestConfirmationEmail, requestPasswordReset } = useAccount();

  //login data
  const [data, setData] = useState({ email: '', password: '', rememberMe: false });

  //error
  const [error, setError] = useState('');

  //success
  const [success, setSuccess] = useState('');

  //processing...
  const [loading, setLoading] = useState(false);

  //attempting to resend verification email
  const [resendVerification, setResendVerification] = useState(false);

  //attempting to request a password reset
  const [resendPasswordReset, setResendPasswordReset] = useState(false);

  //not on default login modal
  const subModal = resendVerification || resendPasswordReset;

  //Password is visible while typing
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  //Toggles password visibility
  const togglePasswordVisibility = useCallback(() => setIsPasswordVisible(!isPasswordVisible), [isPasswordVisible]);

  //get back to login modal
  const backToLogin = () => {
    setResendPasswordReset(false);
    setResendVerification(false);
  };

  //wants to resend verification
  const toResendVerification = () => {
    setResendVerification(true);
    setResendPasswordReset(false);
  };

  //requesting a password reset
  const toResendPassword = () => {
    setResendPasswordReset(true);
    setResendVerification(false);
  };

  //handle text input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  //handle checkbox input change
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };

  //login user
  const loginUser = async () => {
    try {
      const response = await login(data.email, data.password);
      if (!response.accountId)
        setError(formatErrorMessage({ id: 'auth.wrong', defaultMessage: 'Wrong email address or password' }));
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //resend verification email for user
  const resendVerificationEmailForUser = async () => {
    try {
      await requestConfirmationEmail(data.email, data.password);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //request a password reset for user
  const resendPasswordResetForUser = async () => {
    try {
      await requestPasswordReset(data.email);
      setSuccess(
        formatAccountMessage({
          id: 'verification.resent',
          defaultMessage: 'An email was sent to {email}',
          values: { email: data.email },
        }),
      );
    } catch (err) {
      setError(formatErrorMessage({ id: 'wentWrong', defaultMessage: 'Sorry. Something went wrong..' }));
    }
  };

  //form submission
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    //processing starts
    setLoading(true);
    //if user is attempting to resend verification email
    if (resendVerification) resendVerificationEmailForUser();
    //if user is attempting tor equest a password reset
    else if (resendPasswordReset) resendPasswordResetForUser();
    //if user wants to login
    else loginUser();
    //processing ends
    setLoading(false);
  };

  if (loggedIn) return <Redirect target={accountLink} />;

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mt-8">
        <div className="mx-auto w-full max-w-[500px] rounded-lg bg-white px-6 pb-32 pt-16 shadow-2xl dark:bg-primary-200 lg:px-12">
          <form className="space-y-7" onSubmit={handleSubmit}>
            <div className="py-6 text-center">
              <h2 className="text-3xl font-extrabold text-neutral-950">
                {resendPasswordReset
                  ? formatAccountMessage({ id: 'password.reset.headline', defaultMessage: 'Reset your password' })
                  : formatAccountMessage({ id: 'account.sign.in', defaultMessage: 'Sign in to your account' })}
              </h2>
              {!subModal && (
                <h3 className="mt-6 text-md text-neutral-950">
                  {formatAccountMessage({ id: 'details.enter', defaultMessage: 'Please enter your details' })}
                </h3>
              )}
            </div>
            {success && <p className="text-base text-green-600">{success}</p>}
            {error && <p className="text-base text-danger-700">{error}</p>}
            <div>
              <label htmlFor="email" className="block text-md text-neutral-950">
                {formatMessage({ id: 'email', defaultMessage: 'Email' })}
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  placeholder={formatAccountMessage({ id: 'email.enter', defaultMessage: 'Enter your email' })}
                  required
                  className="block w-full appearance-none rounded border border-neutral-600 px-4 py-3.5 text-md placeholder:text-neutral-700 hover:border-neutral-950 focus:border-neutral-950 focus:ring-neutral-950"
                  onChange={handleChange}
                />
              </div>
            </div>

            {!resendPasswordReset && (
              <div>
                <label htmlFor="password" className="block text-md text-neutral-950">
                  {formatAccountMessage({ id: 'password', defaultMessage: 'Password' })}
                </label>
                <div className="relative mt-1">
                  <input
                    id="password"
                    name="password"
                    type={isPasswordVisible ? 'text' : 'password'}
                    autoComplete="current-password"
                    placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
                    required
                    className="block w-full appearance-none rounded border border-neutral-600 px-4 py-3.5 text-md placeholder:text-neutral-700 focus:border-neutral-950 focus:outline-none focus:ring-neutral-950"
                    onChange={handleChange}
                  />
                  <span
                    className="absolute right-4 top-1/2 -translate-y-1/2 text-neutral-700"
                    onClick={togglePasswordVisibility}
                  >
                    {!isPasswordVisible ? <EyeOffIcon className="size-6" /> : <EyeIcon className="size-6" />}
                  </span>
                </div>
              </div>
            )}

            {subModal ? (
              <div>
                <ArrowLeftIcon
                  className="w-4 cursor-pointer text-secondary-600 hover:text-secondary-600/90"
                  onClick={backToLogin}
                />
              </div>
            ) : (
              <div className="mt-2 space-y-2">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="rememberMe"
                      type="checkbox"
                      className="size-5 rounded-lg border-gray-300 text-transparent"
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="remember-me" className="ml-2 block whitespace-nowrap text-sm text-neutral-700">
                      {formatMessage({ id: 'rememberMe', defaultMessage: 'Remember me' })}
                    </label>
                  </div>

                  <div className="text-right text-sm">
                    <span
                      className="cursor-pointer text-secondary-600 underline transition hover:text-secondary-600/90"
                      onClick={toResendPassword}
                    >
                      {formatAccountMessage({ id: 'password.forgot', defaultMessage: 'Forgot your password?' })}
                    </span>
                  </div>
                </div>

                {/* <div className="flex items-center justify-end">
                    <div className="text-sm">
                      <span
                        className="cursor-pointer font-medium text-accent-400 hover:text-accent-500"
                        onClick={toResendVerification}
                      >
                        {formatAccountMessage({
                          id: 'verification.resend',
                          defaultMessage: 'Bestätigungsmail erneut senden',
                        })}
                      </span>
                    </div>
                  </div> */}
              </div>
            )}
            <div>
              <Button type="submit" style="secondary" disabled={loading} fullWidth>
                {subModal
                  ? formatMessage({ id: 'submit', defaultMessage: 'Submit' })
                  : formatAccountMessage({ id: 'sign.in', defaultMessage: 'Sign in' })}
              </Button>
              <p className="mt-4 text-center text-sm text-neutral-950">
                {formatAccountMessage({ id: 'account.doNotHave', defaultMessage: "Don't have an account?" })}{' '}
                <ReferenceLink
                  target={registerLink}
                  className="font-medium text-secondary-600 underline hover:text-secondary-600/90"
                >
                  {formatAccountMessage({ id: 'account.register.here', defaultMessage: 'Register here' })}
                </ReferenceLink>
              </p>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
