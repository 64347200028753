import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="15" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <path d="M8,.4L1.2,5.6v7.7c0,.5.2,1.1.6,1.5s.9.6,1.5.6h9.3c.6,0,1.1-.2,1.5-.6s.6-.9.6-1.5v-7.7L8,.4ZM6.8,13.9v-5.2h2.5v5.2h-2.5ZM13.2,13.3c0,.2,0,.3-.2.4s-.3.2-.4.2h-1.9v-6.7h-5.5v6.7h-1.9c-.2,0-.3,0-.4-.2s-.2-.3-.2-.4v-7l5.2-4.1,5.2,4.1v7Z" />
  </svg>
);

export default Icon;
