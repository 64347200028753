import { FormEvent } from 'react';
import { Cart, PaymentLogos } from '@Types/cart';
import { useForm, FormProvider } from 'react-hook-form';
import CartItemList from './cart-item-list';
import CartSummary from './cart-summary';
import CartErrorComponent from '../cart-error';
import { CartError } from '../errors/CartError';

export interface Props {
  readonly cart: Cart;
  readonly cartErrors?: CartError[];
  readonly submitForm: (e: FormEvent) => void;
  readonly goToProductPage: (url: string) => void;
  readonly editItemQuantity: (lineItemId: string, newQuantity: number) => Promise<void>;
  readonly removeItem: (lineItemId: string) => void;
  readonly paymentLogos: PaymentLogos[];
  readonly defaultShippingCountry?: string;
  readonly deliveryTime?: string;
}

const CartForm = ({
  cart,
  cartErrors,
  submitForm,
  goToProductPage,
  editItemQuantity,
  removeItem,
  paymentLogos,
  defaultShippingCountry,
  deliveryTime,
}: Props) => {
  const formMethods = useForm({
    mode: 'onChange',
  });

  return (
    <>
      {cartErrors && cartErrors.length > 0 && (
        <>
          {cartErrors.map((error, index) => (
            <CartErrorComponent key={`cart-error-${index}`} error={error} />
          ))}
        </>
      )}
      <FormProvider {...formMethods}>
        <form className="md:mt-4 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-10">
          <CartItemList
            cart={cart}
            editItemQuantity={editItemQuantity}
            goToProductPage={goToProductPage}
            removeItem={(lineItemId: string) => removeItem(lineItemId)}
          />
          <CartSummary
            cart={cart}
            onSubmit={submitForm}
            showDiscountsForm={true}
            paymentLogos={paymentLogos}
            defaultShippingCountry={defaultShippingCountry}
            deliveryTime={deliveryTime}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default CartForm;
