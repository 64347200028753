import { Address } from '@Types/account/Address';
import { useFormContext } from 'react-hook-form';
import { CountryOptionItem } from 'components/revelo-ui/checkout/checkout-form';
import { isValidPostalCode } from 'components/revelo-ui/checkout/validation';
import FormFieldInput from 'components/revelo-ui/forms/form-field-input';
import FormFieldSelect from 'components/revelo-ui/forms/form-field-select';
import DefaultAddressSwitch from './DefaultAddressSwitch';

interface Props {
  address: Address | null;
  countryOptions: CountryOptionItem[];
  updateAddressDetails: (field: string, value: string | boolean) => void;
  defaultSwitchTypes?: string[];
}

const AddressInput = ({
  address,
  countryOptions,
  updateAddressDetails,
  defaultSwitchTypes = ['delivery', 'billing'],
}: Props) => {
  const { getValues } = useFormContext();

  return (
    <>
      <FormFieldInput
        name="firstName"
        inputAutoComplete="given-name"
        formId="account.address"
        value={address?.firstName || ''}
        onChange={updateAddressDetails}
        containerClassNames="col-span-full sm:col-span-6"
        validation={{ required: true }}
      />
      <FormFieldInput
        name="lastName"
        inputAutoComplete="family-name"
        formId="account.address"
        value={address?.lastName || ''}
        onChange={updateAddressDetails}
        containerClassNames="col-span-full sm:col-span-6"
        validation={{ required: true }}
      />

      <FormFieldInput
        name="streetName"
        inputAutoComplete="address-line1"
        formId="account.address"
        value={address?.streetName || ''}
        onChange={updateAddressDetails}
        containerClassNames="col-span-full sm:col-span-9"
        validation={{ required: true }}
      />
      <FormFieldInput
        name="streetNumber"
        formId="account.address"
        value={address?.streetNumber || ''}
        onChange={updateAddressDetails}
        containerClassNames="col-span-full sm:col-span-3"
        validation={{ required: true }}
      />
      <FormFieldInput
        name="postalCode"
        inputAutoComplete="postal-code"
        formId="account.address"
        value={address?.postalCode || ''}
        onChange={updateAddressDetails}
        containerClassNames="col-span-full sm:col-span-6"
        validation={{
          required: true,
          validate: (value) => {
            const country = getValues('country') ?? 'DE';
            return isValidPostalCode(value, country);
          },
        }}
      />
      <FormFieldInput
        name="city"
        inputAutoComplete="address-level2"
        formId="account.address"
        value={address?.city || ''}
        onChange={updateAddressDetails}
        containerClassNames="col-span-full sm:col-span-6"
        validation={{ required: true }}
      />
      <FormFieldInput
        name="additionalStreetInfo"
        inputAutoComplete="additionalStreetInfo"
        formId="account.address"
        value={address?.additionalStreetInfo || ''}
        onChange={updateAddressDetails}
        containerClassNames="col-span-full"
      />
      <FormFieldInput
        name="company"
        inputAutoComplete="company"
        formId="account.address"
        value={address?.company || ''}
        onChange={updateAddressDetails}
        containerClassNames="col-span-full"
      />
      <FormFieldSelect
        name="country"
        formId="account.address"
        options={countryOptions}
        selectedOptionValue={(address?.country as string) || 'DE'}
        onChange={updateAddressDetails}
        containerClassNames="col-span-full"
        validation={{
          required: true,
          deps: 'postalCode',
        }}
      />
      {defaultSwitchTypes.length > 0 && (
        <div className="col-span-full">
          {defaultSwitchTypes.map((type: string, index: number) => (
            <DefaultAddressSwitch
              key={`switch-${index}`}
              addressType={type}
              address={address}
              containerClassNames={`relative flex items-start${index > 0 ? ' mt-4' : ''}`}
              onSwitch={updateAddressDetails}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default AddressInput;
