import React from 'react';
import { LineItem } from '@Types/cart/LineItem';
import Price from 'components/commercetools-ui/price';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import CartItemAttributes from './attributes';
import imageUrlResize from '../../../../../helpers/imageUrlResize';
import TrashIcon from '../../../../icons/trash';
import { TaxHandler } from '../../../utils/TaxHandler';

interface Props {
  lineItem: LineItem;
  cartState?: string;
  goToProductPage: (_url: string) => void;
  editItemQuantity: (lineItemId: string, newQuantity: number) => void;
  removeItem: (lineItemId: string) => void;
}

const CartItem = ({ lineItem, goToProductPage, editItemQuantity, removeItem, cartState }: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });

  const taxClass = TaxHandler.getTaxCssClassForItem(lineItem);

  const onButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string) => {
    e.stopPropagation();
    removeItem(id);
  };

  return (
    <div
      className="relative mb-1.5 flex cursor-pointer gap-4 border border-neutral-200 px-4 transition-colors md:mb-0 md:hover:bg-neutral-50 lg:gap-10"
      onClick={() => goToProductPage(lineItem._url)}
    >
      <div className="flex basis-[35%] items-center pb-3 mix-blend-multiply">
        <Image src={imageUrlResize(lineItem.variant.images[0], 'medium')} alt={lineItem.name} />
      </div>

      <div className="flex basis-[65%] items-center">
        <div className="flex w-full flex-wrap gap-4 py-4 md:gap-6 md:py-6">
          <div className="flex w-full flex-wrap gap-2">
            <div className="basis-full text-sm font-bold text-neutral-950 md:text-md">
              <div className="">{lineItem.variant.attributes.brand[0]} </div>
              {lineItem.variant.attributes.model_name}
            </div>

            <div className="inline-flex basis-full">
              <span className="text-sm after:content-['・'] last:after:hidden">
                {lineItem.variant.attributes.model_year}
              </span>
              <CartItemAttributes
                variant={lineItem.variant}
                attributes={['frame_height_manufacturer', 'mileage_in_km']}
              />
            </div>
          </div>

          <div className="flex w-full justify-between">
            <div className="mb-3 mt-auto flex md:items-end md:justify-between lg:mb-0">
              <div className="">
                {lineItem.discountedPrice ? (
                  <>
                    <Price
                      price={lineItem.price}
                      className="text-sm font-semibold text-gray-900 line-through lg:text-base"
                    />
                    <Price
                      price={lineItem.discountedPrice}
                      className={`rounded-4xl bg-accent-300 px-2 text-sm font-bold ${taxClass}`}
                    />
                  </>
                ) : (
                  <Price
                    price={lineItem.price}
                    className={`rounded-4xl bg-accent-300 px-2 text-sm font-bold ${taxClass}`}
                  />
                )}
              </div>
            </div>
            {cartState !== 'Frozen' && (
              <button
                type="button"
                onClick={(e) => onButtonClick(e, lineItem.lineItemId)}
                className="inline-flex text-neutral-950 hover:text-gray-500"
              >
                <span className="sr-only">{formatMessage({ id: 'remove', defaultMessage: 'Remove' })}</span>
                <TrashIcon className="size-6" aria-hidden="true" />
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
