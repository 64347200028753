import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <polygon points="9 21.4 7.6 20 15.6 12 7.6 4 9 2.6 18.4 12 9 21.4" />
  </svg>
);

export default Icon;
