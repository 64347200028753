import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import type { NotificationResult } from '@Types/notification';
import { Product } from '@Types/product/Product';
import { Result } from '@Types/product/Result';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { updateURLParams } from '../../../../helpers/utils/updateURLParams';
import Spinner from '../../../commercetools-ui/spinner';
import ProductList from '../../products/product-list';
import { FilterUtils } from '../../utils/FilterUtils';
import { NotificationUtils } from '../../utils/NotificationUtils';

/**
 * todo - product list filtering is not working right now, as it still relies on url query parameters - which are not
 *        required/set by notification system.
 */
export type NotificationResultProps = {
  notification: NotificationResult;
  visibleFilters?: string;
  debounceDelay?: number;
};

const NotificationResult = ({ notification, visibleFilters = '', debounceDelay = 1000 }: NotificationResultProps) => {
  const { formatMessage } = useFormat({ name: 'account' });
  const router = useRouter();

  const [products, setProducts] = React.useState<Result>(undefined);

  const productListTitle =
    notification.title ??
    notification.snippets?.['notificationTitle'] ??
    formatMessage({
      id: `notificationTitle`,
      defaultMessage: `Notification ${notification.notificationId}`,
    });

  useEffect(() => {
    if (notification.products?.count > 0) {
      const resultFacets = notification.products.facets ?? [];
      const notificationFacets = NotificationUtils.getNotificationFacets(notification.criteria, resultFacets);

      setTimeout(() => {
        const currentUrl = updateURLParams(FilterUtils.getFilteringParams(notificationFacets), true);
        router.push(currentUrl).then(() => setProducts(notification.products));
      }, 2000);
    }
  }, []);

  return (
    <main className="flex flex-col lg:flex-row">
      <div className="mx-auto flex-auto px-4 py-5 sm:px-6 lg:px-8 lg:py-32">
        {products === undefined ? (
          <div className="flex items-stretch justify-center px-12 py-10">
            <Spinner />
          </div>
        ) : (
          <ProductList
            products={products.items as Product[]}
            previousCursor={products.previousCursor}
            nextCursor={products.nextCursor}
            facets={products.facets}
            totalProducts={products.total}
            category={undefined}
            visibleFilters={visibleFilters.split(',')}
            noProductsMarkdown={notification.snippets?.['noProductsMakdown'] ?? ''}
            categoryTitle={productListTitle}
            debounceDelay={debounceDelay}
            banners={[]}
          />
        )}
      </div>
    </main>
  );
};

export default NotificationResult;
