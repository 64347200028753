import React, { useEffect } from 'react';
import { Controller, RegisterOptions, useFormContext } from 'react-hook-form';

interface Props {
  readonly name: string;
  readonly containerClassNames?: string;
  readonly classNames?: string;
  readonly options: { display: string; data: string; disabled?: boolean }[];
  readonly value: string;
  readonly onChange: (propName: string, newValue: string) => void;
  readonly validation?: RegisterOptions;
}

const SelectElement = ({ name, containerClassNames, classNames, options, value, onChange, validation }: Props) => {
  const { control, setValue } = useFormContext();

  useEffect(() => {
    setValue(name, value || '');
  }, [value, name, setValue]);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    onChange(name, newValue);
  };

  return (
    <div className={containerClassNames ?? 'mt-2'}>
      <Controller
        name={name}
        control={control}
        defaultValue={value || ''}
        rules={validation}
        render={({ field }) => (
          <select
            id={name}
            {...field}
            className={
              classNames ||
              'mt-2 block w-full rounded border border-neutral-600 py-3 pl-4 pr-10 text-md focus:border-neutral-950 focus:ring-neutral-950'
            }
            onChange={(e) => {
              field.onChange(e);
              handleChange(e);
            }}
          >
            {options.map((option, n) => (
              <option key={n} value={option.data} disabled={option.disabled}>
                {option.display}
              </option>
            ))}
          </select>
        )}
      />
    </div>
  );
};

export default SelectElement;
