import { useFormat } from '../../../../helpers/hooks/useFormat';
import CartItemErrorComponent from '../../cart/cart-error';
import DiscountErrorComponent from '../../cart/cart-error/discount-error';
import { CartItemError } from '../../cart/errors/CartItemError';
import { DiscountError } from '../../cart/errors/DiscountError';
import { PaymentResponseError } from '../errors/PaymentResponseError';
import PaymentErrorComponent from '../payment-error';

export interface Props {
  readonly error: Error;
}

const CheckoutError = ({ error }: Props) => {
  const { formatMessage } = useFormat({ name: 'checkout' });

  if (error instanceof CartItemError) {
    return <CartItemErrorComponent error={error} />;
  }

  if (error instanceof PaymentResponseError) {
    return <PaymentErrorComponent error={error} />;
  }

  if (error instanceof DiscountError) {
    return <DiscountErrorComponent error={error} />;
  }

  return (
    <section aria-labelledby="checkout-error" className="flex-auto overflow-y-auto px-0 pb-4 pt-0">
      <div className="border-y border-red-500 bg-red-100 px-4 py-3 text-red-700" role="alert">
        <div className="pt-2 text-sm">
          {formatMessage({
            id: 'checkout.generalError.description',
            defaultMessage: 'Something went wrong while processing your order',
          })}
        </div>
        {error?.message && (
          <div className="pt-2 text-sm">
            <span className="italic">{error.message}</span>
          </div>
        )}
      </div>
    </section>
  );
};

export default CheckoutError;
