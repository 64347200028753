import React, { useEffect, useState } from 'react';
import { TagManager } from '../../../../frontastic/lib/tag-manager';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import SearchIcon from '../../../icons/search';

type FaqSearchProps = {
  placeholder?: string;
  noResultsMsg?: string;
  trackingEventName?: string;
};

type SearchableElements = {
  parent: Element;
  children: NodeListOf<Element>;
};

type SearchableContent = {
  content: string;
  parentIndex: number;
  index: number;
};

const ContentSearch: React.FC<FaqSearchProps> = ({ placeholder, noResultsMsg, trackingEventName }) => {
  const [searchableElements, setSearchableElements] = useState<Partial<SearchableElements[]>>([]);
  const [searchableContent, setSearchableContent] = useState<Partial<SearchableContent[]>>([]);
  const [accordionsHeadline, setAccordionsHeadline] = useState<Partial<Element[]>>([]);
  const [showNoResultsMsg, setShowNoResultsMsg] = useState<boolean>(false);
  const [preventDataLayerPush, setPreventDataLayerPush] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const { formatMessage } = useFormat({ name: 'common' });

  const removeAllHiddenClasses = (items: SearchableContent[], elements: SearchableElements[]) => {
    accordionsHeadline?.forEach((headline) => headline.classList.remove('hidden'));
    items.forEach((item) => {
      elements[item.parentIndex].parent.classList.remove('hidden');
      elements[item.parentIndex].children[item.index].parentElement.classList.remove('hidden');
    });
  };

  const updateDataLayer = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value?.toLowerCase();

    if (inputValue?.length && trackingEventName?.length && !preventDataLayerPush) {
      new TagManager().customEvent(trackingEventName, { searchTerm: inputValue }).executePush();
    }
  };

  const prepareSearchResults = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.currentTarget.value?.toLowerCase();
    setSearchValue(inputValue);
    setPreventDataLayerPush(false);
    setShowNoResultsMsg(false);
    const unmatchedResults = searchableContent.filter((item) => !item.content.includes(inputValue));

    if (!searchableElements.length || !searchableContent.length || !unmatchedResults.length) {
      return removeAllHiddenClasses(searchableContent, searchableElements);
    }

    if (unmatchedResults.length === searchableContent.length) {
      setShowNoResultsMsg(true);
      return removeAllHiddenClasses(searchableContent, searchableElements);
    }

    accordionsHeadline?.forEach((headline) => headline.classList.add('hidden'));

    searchableContent.forEach((item) => {
      searchableElements[item.parentIndex].children[item.index].parentElement.classList.remove('hidden');
      const unmatched = unmatchedResults.find(
        (element) => element.parentIndex === item.parentIndex && element.index === item.index,
      );

      if (unmatched) {
        const unmatchedElement = searchableElements[unmatched.parentIndex].children[
          unmatched.index
        ] as HTMLButtonElement;

        if (unmatchedElement?.getAttribute('aria-expanded') === 'true') {
          unmatchedElement.click();
          setTimeout(() => unmatchedElement.parentElement.classList.add('hidden'), 400);
        } else {
          unmatchedElement.parentElement.classList.add('hidden');
        }

        const allChildrenHidden =
          Array.from(searchableElements[unmatched.parentIndex].children).filter((child) =>
            child.parentElement.classList.contains('hidden'),
          ).length === searchableElements[unmatched.parentIndex].children.length;
        const closestSpacer = searchableElements[unmatched.parentIndex].parent
          .closest('div[style^="grid-column"]')
          ?.querySelector('.spacer');

        searchableElements[unmatched.parentIndex].parent.classList.toggle('hidden', allChildrenHidden);

        if (closestSpacer) {
          closestSpacer.classList.toggle('hidden', allChildrenHidden);
        }
      }
    });
  };

  useEffect(() => {
    const accordionsWrappers = document?.querySelectorAll('.accordion-wrapper');
    setSearchableContent([]);
    setSearchableElements([]);

    if (!accordionsWrappers?.length) {
      return;
    }

    accordionsWrappers.forEach((accordionWrapper, parentIndex) => {
      const accordions = accordionWrapper.querySelectorAll('button');
      const accordionHeadline = accordionWrapper.querySelector('.accordion-headline');

      if (accordionHeadline) {
        setAccordionsHeadline((state) => [...state, accordionHeadline]);
      }

      if (accordions) {
        setSearchableElements((state) => [...state, { parent: accordionWrapper, children: accordions }]);

        accordions.forEach((accordion, index) => {
          const accordionContent = accordion.innerHTML.replace(/<[^>]*>/g, '').toLowerCase();

          setSearchableContent((state) => [...state, { content: accordionContent, parentIndex, index }]);
        });
      }
    });
  }, []);

  return (
    <div>
      <div className="relative w-full bg-white dark:bg-primary-400 md:w-[400px]">
        <SearchIcon
          className="absolute left-4 top-[50%] size-5 translate-y-[-50%] cursor-pointer fill-neutral-400 hover:fill-accent-500"
          aria-hidden="true"
        />
        <input
          type="text"
          name="faqSearch"
          id="faqSearch"
          className="block w-full rounded-lg border-gray-300 py-3 pl-12 pr-4 shadow focus:border-accent-400 focus:ring-accent-400 sm:text-sm"
          placeholder={placeholder ?? `${formatMessage({ id: 'search', defaultMessage: 'Search' })}...`}
          onChange={prepareSearchResults}
          onBlur={updateDataLayer}
          onFocus={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPreventDataLayerPush(e.currentTarget.value?.toLowerCase() === searchValue)
          }
        />
      </div>
      {showNoResultsMsg && noResultsMsg?.length && (
        <div className="w-full bg-white p-4 text-accent-400 md:w-[400px]">{noResultsMsg}</div>
      )}
    </div>
  );
};

export default ContentSearch;
