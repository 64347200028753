import React from 'react';
import { Variant } from '@Types/product/Variant';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
  variant: Variant;
  attributes: string[];
}

type AttributeDisplay = {
  id: string;
  name: string;
  displayValue: string;
};

const CartItemAttributes = ({ variant, attributes }: Props) => {
  const { formatMessage } = useFormat({ name: 'product' });

  const getAttributeDisplay = (attributeId: string): AttributeDisplay | null => {
    const value = variant.attributes?.[attributeId];
    if (!value) {
      return null;
    }

    let name, display;
    switch (attributeId) {
      case 'milage_in_km':
      case 'mileage_in_km':
        name = formatMessage({ id: 'mileage', defaultMessage: 'Laufleistung' });
        display = `${value} km`;
        break;
      case 'frame_height_manufacturer':
        name = formatMessage({ id: 'frameSize', defaultMessage: 'Rahmengröße' });
        display = value;
        break;
      default:
        name = formatMessage({ id: attributeId, defaultMessage: attributeId.replace(/_/g, ' ') });
        display = value;
    }

    return { id: attributeId, name: name, displayValue: display };
  };

  return (
    <>
      {attributes.map((attributeId: string, index) => {
        const display = getAttributeDisplay(attributeId);
        if (display !== null) {
          return (
            <span className="text-sm after:content-['・'] last:after:hidden" key={display.id}>
              {display.displayValue}
            </span>
          );
        }
      })}
    </>
  );
};

export default CartItemAttributes;
