import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { Payment } from '@Types/cart/Payment';
import { MediaItemWithMods, useCart } from 'frontastic';
import Image from 'frontastic/lib/image';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import Spinner from '../../../commercetools-ui/spinner';
import { PaymentHandler } from '../../checkout/utils/PaymentHandler';

export type PendingPaymentInfoProps = {
  payment: Payment;
  title?: string;
  text?: string;
  image?: MediaItemWithMods;
};

const PendingPaymentInfo = ({ payment, title, text, image }: PendingPaymentInfoProps) => {
  const router = useRouter();
  const { unfreezeCart } = useCart();
  const { formatMessage } = useFormat({ name: 'checkout' });
  const checkoutUrl = PaymentHandler.getCheckoutUrl(payment);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const doRedirectToPsp = async () => {
    if (checkoutUrl !== null) {
      setIsLoading(true);
      await router.push(checkoutUrl);
    }
  };

  const doUnfreezeCart = async () => {
    setIsLoading(true);
    await unfreezeCart().then(() => router.push('/cart'));
  };

  text =
    text ??
    formatMessage({
      id: 'payment.pending.infotext',
      defaultMessage: 'Payment synchronization failed. Please finish payment ... ',
    });

  title =
    title ??
    formatMessage({
      id: 'payment.pending.infotitle',
      defaultMessage: 'Payment pending',
    });

  return (
    <main className="flex flex-col lg:flex-row">
      <div className="flex-none lg:w-1/2">
        {image && (
          <div className="overflow-hidden lg:h-full lg:pr-4 xl:pr-12">
            <Image media={image} layout="responsive" objectFit="contain" alt="Pending payment info" />
          </div>
        )}
      </div>

      {isLoading ? (
        <div className="mx-auto flex-auto px-4 py-5 sm:px-6 lg:px-8 lg:py-32">
          <Spinner />
        </div>
      ) : (
        <div className="mx-auto flex-auto px-4 py-5 sm:px-6 lg:px-8 lg:py-32">
          <p className="mt-7 text-4xl font-extrabold tracking-tight text-red-700 sm:text-5xl">{title}</p>
          <p className="mt-7 text-base text-gray-500 dark:text-light-100">{text}</p>

          <div className="mt-6 flex flex-col py-6 text-right lg:flex-row">
            {checkoutUrl && (
              <div className="flex-none text-left lg:w-1/2">
                <p
                  className="cursor-pointer text-sm font-medium text-secondary-600 underline hover:text-secondary-600/90"
                  onClick={doRedirectToPsp}
                >
                  <span aria-hidden="true"> &larr;</span>
                  {formatMessage({ id: 'checkout.payment.finalize', defaultMessage: 'Finalize payment' })}
                </p>
              </div>
            )}
            <div className="flex-auto">
              <p
                className="cursor-pointer text-sm font-medium text-secondary-600 underline hover:text-secondary-600/90"
                onClick={doUnfreezeCart}
              >
                {formatMessage({ id: 'checkout.payment.unfreeze', defaultMessage: 'Unfreeze cart now' })}
                <span aria-hidden="true"> &rarr;</span>
              </p>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default PendingPaymentInfo;
