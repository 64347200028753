import React from 'react';
import { useRouter } from 'next/router';
import { MediaItemWithMods } from 'frontastic';
import Image from 'frontastic/lib/image';
import { ErrorHandler } from './utils/ErrorHandler';
import { useFormat } from '../../../helpers/hooks/useFormat';

export type OrderErrorProps = {
  title?: string;
  text?: string;
  image?: MediaItemWithMods;
  error?: Error;
  errorDetails?: ErrorDetails[];
};

export interface ErrorDetails {
  type?: string;
  title?: string;
  text?: string;
  image?: MediaItemWithMods;
}

const OrderError = ({ error, title, text, image, errorDetails }: OrderErrorProps) => {
  const router = useRouter();
  const { formatMessage } = useFormat({ name: 'checkout' });
  const details = error && errorDetails ? ErrorHandler.getErrorDetails(error, errorDetails) : null;

  text =
    text ??
    details?.text ??
    formatMessage({
      id: ErrorHandler.DEFAULT_DESCRIPTION_ID,
      defaultMessage:
        'Sorry! Someone ordered the same bike at the same time and you were a little slow. Your payment will be ' +
        'refunded asap. Please note that in some cases it may take a few days to process your order. If you have ' +
        'any questions, please contact our customer service.',
    });

  title =
    title ??
    details?.title ??
    formatMessage({
      id: ErrorHandler.DEFAULT_TITLE_ID,
      defaultMessage: 'Failed to place order',
    });

  return (
    <main className="flex flex-col lg:flex-row">
      <div className="flex-none lg:w-1/2">
        {image && (
          <div className="overflow-hidden lg:h-full lg:pr-4 xl:pr-12">
            <Image media={image ?? details?.image} layout="responsive" objectFit="contain" alt="Order error" />
          </div>
        )}
      </div>
      <div className="mx-auto flex-auto px-4 py-5 sm:px-6 lg:px-8 lg:py-32">
        <p className="mt-7 text-4xl font-extrabold tracking-tight text-red-700 sm:text-5xl">{title}</p>
        <p className="mt-7 text-base text-gray-500 dark:text-light-100">{text}</p>

        <div className="mt-9 border-t border-gray-200 py-6 text-right">
          <p
            className="cursor-pointer text-sm font-medium text-accent-400 hover:text-accent-500"
            onClick={() => router.push('/support')}
          >
            {formatMessage({ id: 'customerService', defaultMessage: 'Customer service' })}
            <span aria-hidden="true"> &rarr;</span>
          </p>
        </div>
      </div>
    </main>
  );
};

export default OrderError;
