import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Address } from '@Types/account';
import { Payment } from '@Types/cart';
import { CartError as CartErrorType } from '@Types/cart/CartError';
import { mutate } from 'swr';
import { useCheckout } from '@Provider/revelo-checkout';
import Spinner from 'components/commercetools-ui/spinner';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import { useAccount, useCart } from 'frontastic';
import { CartDetails, checkCartItemAvailability, updateItemShippingMethods } from '../../../frontastic/actions/cart';
import { TagManager } from '../../../frontastic/lib/tag-manager';
import EmptyCart from '../cart/empty-cart';
import { OrderErrorProps } from '../order-error';
import CheckoutErrorList from './checkout-error/checkout-error-list';
import CheckoutForm, { CountryOptionItem } from './checkout-form';
import { CheckoutValidationError } from './errors/CheckoutValidationError';
import { PaymentResponseError } from './errors/PaymentResponseError';
import { PaymentHandler } from './utils/PaymentHandler';
import { ShippingHandler } from './utils/ShippingHandler';
import { isCheckoutDataValid, isItemsListValid } from './validation';
import { CartError } from '../cart/errors/CartError';
import { CartErrorUtils } from '../utils/CartErrorUtils';

interface Props {
  loginLink?: Reference;
  billingCountryOptions?: CountryOptionItem[];
  shippingCountryOptions?: CountryOptionItem[];
  cartErrors?: Array<CartError | CartErrorType>;
  orderErrorData?: OrderErrorProps;
  paymentDescriptionList?: [];
  paymentPreselection?: string;
  deliveryTime?: string;
  paymentB2BDisclaimer?: string;
}

const Checkout = ({
  billingCountryOptions,
  shippingCountryOptions,
  cartErrors,
  orderErrorData,
  paymentDescriptionList,
  paymentPreselection,
  deliveryTime,
  paymentB2BDisclaimer,
}: Props) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
  const { account, loggedIn, updateAddresses } = useAccount();

  const router = useRouter();
  const checkoutData = useCheckout();
  const {
    data,
    getPaymentMethods,
    getShippingMethods,
    getShippingMethodMapping,
    setPaymentMethod,
    updateCart,
    refreshCart,
    triggerPayment,
    confirmCartErrors,
  } = useCart();

  const [activePaymentMethod, setActivePaymentMethod] = useState<Payment | null>(null);
  const [checkoutErrors, setCheckoutErrors] = useState<Error[]>([]);
  const [refreshRequired, setRefreshRequired] = useState<boolean>(false);
  const [checkStatus, setCheckStatus] = useState<boolean>(true);
  const currentPayment = useRef<Payment | null>();

  const handlePaymentRedirect = async (payment: Payment): Promise<void> => {
    const redirectUrl = PaymentHandler.getCheckoutUrl(payment);
    if (redirectUrl) {
      if (redirectUrl === router.query['path'] || redirectUrl === router.asPath) {
        setRefreshRequired(true);
        checkoutData.setIsSubmitting(false);
      } else {
        await router.push(redirectUrl);
        checkoutData.setIsSubmitting(true);
      }
    } else {
      checkoutData.setLastCheckoutError(new PaymentResponseError('Failed to initialize payment'));
      checkoutData.setIsSubmitting(false);
    }
  };

  const validateSubmit = async (): Promise<void> => {
    const res = await checkCartItemAvailability();
    if (res) {
      throw new CheckoutValidationError(orderErrorData.text, 'StockHandlingError');
    }
  };

  const submitForm = async () => {
    checkoutData.setIsSubmitting(true);
    window.scrollTo(0, 0);

    setCheckoutErrors([]);

    try {
      await validateSubmit();

      if (loggedIn && addressesChanged()) {
        await updateAddresses(
          checkoutData.billingSameAsShipping
            ? [{ ...checkoutData.shippingAddress, isDefaultShippingAddress: true, isDefaultBillingAddress: true }]
            : [
                { ...checkoutData.billingAddress, isDefaultBillingAddress: true },
                { ...checkoutData.shippingAddress, isDefaultShippingAddress: true },
              ],
        );
      }

      await updateCartDetails();
      await setPaymentMethod(checkoutData.selectedPaymentMethod);
      await updateItemShippingMethods(
        data?.lineItems.map((item) => ({
          ...item,
          shippingDetails: {
            shippingMethodId:
              item.shippingDetails?.shippingInfoId ?? checkoutData.selectedShippingMethods[item.lineItemId],
            shippingAddress: getItemShippingAddress(),
          },
        })),
      );

      const payment = await triggerPayment();
      await handlePaymentRedirect(payment);
    } catch (error: any) {
      mutate('/action/cart/getCart');

      checkoutData.setIsSubmitting(false);
      checkoutData.setLastCheckoutError(
        error instanceof CartError ? error : PaymentHandler.getCheckoutResponseError(error),
      );
    }
  };

  // Note: the shipping address is the primary address, so we have to use it in case of "billingSameAsShipping"
  const getBillingDetails = () => {
    const billingDetails = checkoutData.billingSameAsShipping
      ? checkoutData.shippingAddress
      : checkoutData.billingAddress;

    if (loggedIn && account) {
      billingDetails.addressId =
        account.addresses?.find((accAddress) => accAddress.isDefaultBillingAddress)?.addressId ??
        billingDetails.addressId;
      checkoutData.setBillingAddress(billingDetails);
    }
    return billingDetails;
  };

  const updateCartDetails = async () => {
    const payload: CartDetails = {
      account: {
        email: checkoutData.email,
      },
      billing: getBillingDetails(),
      payment: checkoutData.selectedPaymentMethod,
    };

    if (data.shippingMode === 'Single') {
      payload.shipping = loggedIn
        ? account.addresses.find((address) => address.addressId === checkoutData.shippingAddress.addressId)
        : !checkoutData.billingSameAsShipping
        ? checkoutData.shippingAddress
        : null;
    }

    await updateCart(payload);
  };

  const initCheckoutData = () => {
    const billingAddress = getInitAddress('billing');
    const shippingAddress = getInitAddress('shipping');
    const isEmptyBillingAddress = ShippingHandler.isEmptyAddress(billingAddress, ['country']);
    const isEmptyShippingAddress = ShippingHandler.isEmptyAddress(shippingAddress, ['country']);
    const billingSameAsShipping =
      ShippingHandler.isSameAddress(billingAddress, shippingAddress) || isEmptyBillingAddress || isEmptyShippingAddress;

    billingAddress.phone = billingAddress.phone ?? shippingAddress.phone;
    shippingAddress.phone = shippingAddress.phone ?? billingAddress.phone;

    checkoutData.setEmail(data?.email || account?.email || checkoutData.email || '');
    checkoutData.setBillingSameAsShipping(billingSameAsShipping);
    checkoutData.setBillingAddress(billingSameAsShipping && isEmptyBillingAddress ? shippingAddress : billingAddress);
    checkoutData.setShippingAddress(billingSameAsShipping && isEmptyShippingAddress ? billingAddress : shippingAddress);
  };

  const isValidInitAddress = (address: Address) => {
    return !ShippingHandler.isEmptyAddress(address, ['country']);
  };

  const getCartAddress = (type: string) => {
    if (!data) {
      return undefined;
    }

    const address = type !== 'shipping' ? data?.billingAddress : ShippingHandler.getCartShippingAddress(data);
    if (!address || !isValidInitAddress(address)) {
      return undefined;
    }
    return { ...address, country: address.country || 'DE', source: 'cart' };
  };

  const getCheckoutDataAddress = (type: string) => {
    const address = type === 'shipping' ? checkoutData.shippingAddress : checkoutData.billingAddress;
    if (!address || !isValidInitAddress(address)) {
      return undefined;
    }
    return { ...address, country: address.country || 'DE', source: 'cart' };
  };

  const getAccountAddress = (type: string) => {
    if (loggedIn) {
      const current = type === 'shipping' ? checkoutData.shippingAddress : checkoutData.billingAddress;
      const address =
        account?.addresses?.find((address) =>
          type !== 'shipping' ? address.isDefaultBillingAddress : address.isDefaultShippingAddress,
        ) ?? account?.addresses?.find((address) => address !== undefined);

      if (address && isValidInitAddress(address)) {
        return {
          ...ShippingHandler.mergeAddresses(address, current),
          source: 'account',
          country: current.country || address.country || 'DE',
        };
      }
    }
    return undefined;
  };

  const getInitAddress = (type: string) => {
    return (
      getCheckoutDataAddress(type) ??
      getCartAddress(type) ??
      getAccountAddress(type) ?? {
        firstName: account?.firstName || '',
        lastName: account?.lastName || '',
        phone: '',
        streetName: '',
        streetNumber: '',
        postalCode: '',
        city: '',
        country: data?.shippingAddress?.country ?? checkoutData?.shippingAddress?.country ?? 'DE',
        source: 'init',
      }
    );
  };

  // Note: the shipping address is the primary address
  const getItemShippingAddress = () => {
    const shippingAddress = { ...checkoutData.shippingAddress };

    if (loggedIn && account) {
      shippingAddress.addressId =
        account.addresses?.find((accAddress) => accAddress.isDefaultShippingAddress)?.addressId ??
        shippingAddress.addressId;
      checkoutData.setShippingAddress(shippingAddress);
    }

    return shippingAddress;
  };

  const updatePaymentMethod = (paymentMethod) => {
    const refPayment = currentPayment.current || null;
    if (refPayment === null || refPayment.id !== paymentMethod.id) {
      changePaymentMethod(paymentMethod);
    }
  };

  const changePaymentMethod = (paymentMethod) => {
    if (!checkoutData.submitting) {
      setCheckStatus(true);
      setActivePaymentMethod(paymentMethod);
    }
  };

  const isCheckoutDataProcessed = () => {
    return !!data?.cartId && !!data?.payments;
  };

  const getUnresolvedPaymentError = (payment: Payment) => {
    const message = payment.paymentMethod; //orderErrorData?.text && orderErrorData.text.length ? orderErrorData.text : payment.paymentId;
    return new PaymentResponseError(`Unresolved payment - ${message}`);
  };

  const processPayment = (payment: Payment): void => {
    switch (true) {
      case PaymentHandler.isPaymentSettled(payment):
        setCheckStatus(true);
        router.push('/thank-you');
        break;
      case PaymentHandler.isPaymentUnresolved(payment):
        checkoutData.setLastCheckoutError(getUnresolvedPaymentError(payment));
        setRefreshRequired(true);
        break;
      case PaymentHandler.isPaymentFailed(payment):
        checkoutData.setLastCheckoutError(new PaymentResponseError(''));
        setCheckStatus(false);
        break;
      default:
        setCheckStatus(false);
    }
  };

  const addressesChanged = () => {
    const defaultBillingAddress = account?.addresses?.find((address) => address.isDefaultBillingAddress);
    const defaultShippingAddress = account?.addresses?.find((address) => address.isDefaultShippingAddress);

    return (
      !defaultBillingAddress ||
      !defaultShippingAddress ||
      !ShippingHandler.isSameAddress(defaultBillingAddress, checkoutData.billingAddress) ||
      !ShippingHandler.isSameAddress(defaultShippingAddress, checkoutData.shippingAddress)
    );
  };

  useEffect(() => {
    setCheckStatus(true);
    setCheckoutErrors(CartErrorUtils.getCartErrorList(cartErrors ?? []));
  }, []);

  useEffect(() => {
    if (checkoutData.submitting) {
      return;
    }

    initCheckoutData();

    checkoutData.setIsProcessing(!isCheckoutDataProcessed());
  }, [data?.cartId, data?.billingAddress, data?.shippingAddress]);

  useEffect(() => {
    // Check if we got a cart replicant, in this case we may want to inform the customer
    if (checkoutData.processing || checkoutData.fetchingPaymentMethods || !data.replicant) {
      return;
    }

    if (data.replicant.reason !== undefined && !!data?.replicant?.customerNotificationRequired) {
      (async () => {
        checkoutData.setLastCheckoutError(new PaymentResponseError(data.replicant.reason));
        await confirmCartErrors();
      })();
    }
  }, [data?.replicant, checkoutData.processing, checkoutData.fetchingPaymentMethods]);

  useEffect(() => {
    if (!data?.payments || checkoutData.submitting) {
      return;
    }

    // Handle pending payment
    if (PaymentHandler.hasPendingPayment(data) && data.cartState === 'Frozen') {
      const { method } = router.query;
      router.push({ pathname: '/thank-you', query: { method: method } });
      return;
    }

    // Handle partial payment
    if (PaymentHandler.hasPartialPayment(data)) {
      const { method } = router.query;
      router.push({ pathname: '/thank-you', query: { method: method } });
      return;
    }

    const cartPaymentMethod = PaymentHandler.getMolliePayment(data);
    if (!cartPaymentMethod) {
      // Check, if we have a failed payment, in this case we should show an error message
      const lastPaymentMethod = PaymentHandler.getMolliePayment(data, true);
      if (!!lastPaymentMethod) {
        processPayment(lastPaymentMethod);
        setCheckStatus(false);
      }
      return;
    }

    updatePaymentMethod(cartPaymentMethod);
    checkoutData.setIsProcessing(!isCheckoutDataProcessed());
  }, [data?.payments]);

  useEffect(() => {
    if (!data?.cartId || checkoutData.submitting) {
      return;
    }

    if (!PaymentHandler.cartRequiresPayment(data)) {
      checkoutData.setPaymentMethods([]);
      return;
    }

    (async () => {
      checkoutData.setIsFetchingPaymentMethods(true);
      const paymentMethods = await getPaymentMethods();
      checkoutData.setPaymentMethods(paymentMethods);
      checkoutData.setIsFetchingPaymentMethods(false);
    })();
  }, [data?.sum?.centAmount]);

  useEffect(() => {
    if (!data?.cartId || checkoutData.submitting) {
      return;
    }

    (async () => {
      const shippingMethods = await getShippingMethods();
      const shippingMethodMapping = await getShippingMethodMapping();

      checkoutData.setShippingMethods(shippingMethods);
      checkoutData.setShippingMethodMapping(shippingMethodMapping);
    })();
  }, [data?.lineItems, data?.billingAddress, data?.shippingAddress]);

  useEffect(() => {
    if (!checkoutData?.recalculationRequired || checkoutData?.calculatingShipping || !data?.lineItems?.length) {
      return;
    }

    try {
      checkoutData.setIsCalculatingShipping(true);
      const updateShippingDetails = async () => {
        await updateItemShippingMethods(
          data.lineItems.map((item) => ({
            ...item,
            shippingDetails: {
              shippingMethodId:
                item.shippingDetails?.shippingInfoId ?? checkoutData.selectedShippingMethods[item.lineItemId],
              shippingAddress: getItemShippingAddress(),
            },
          })),
        );
      };

      updateShippingDetails().then(() => {
        checkoutData.setRecalculationRequired(false);
        checkoutData.setIsCalculatingShipping(false);
        mutate('/action/cart/getCart');
      });
    } catch (error) {
      checkoutData.setLastCheckoutError(error);
      checkoutData.setRecalculationRequired(false);
    }
  }, [checkoutData?.recalculationRequired]);

  useEffect(() => {
    currentPayment.current = activePaymentMethod;
    if (!!activePaymentMethod) {
      processPayment(activePaymentMethod);
      return;
    }

    if (PaymentHandler.isRedirectFromMollie(router.query)) {
      const { method } = router.query;
      if (PaymentHandler.isNonInteractiveType(method)) {
        router.push({ pathname: '/thank-you', query: { method: method } });
        return;
      }
    }
    setCheckStatus(false);
  }, [activePaymentMethod]);

  useEffect(() => {
    if (refreshRequired) {
      refreshCart().then(() => {
        changePaymentMethod(null);
        setRefreshRequired(false);
      });
    }
  }, [refreshRequired, refreshCart]);

  useEffect(() => {
    if (checkoutData.lastCheckoutError) {
      const errorList = checkoutData.lastCheckoutError instanceof CheckoutValidationError ? [] : checkoutErrors;
      const inList = errorList.find(
        (error) => checkoutData.lastCheckoutError !== false && error.message === checkoutData.lastCheckoutError.message,
      );

      if (!inList) {
        errorList.push(checkoutData.lastCheckoutError);
      }

      setCheckoutErrors(errorList);
      window.scrollTo(0, 0);
    }
  }, [checkoutData.lastCheckoutError]);

  useEffect(() => {
    if (!!data) {
      new TagManager().beginCheckoutEvent(data).executePush();
    }
  }, [data?.cartId]);

  return (
    <main className="lg:py-10">
      <h1 className="sr-only">{formatCartMessage({ id: 'checkout', defaultMessage: 'Checkout' })}</h1>
      {checkStatus || checkoutData.processing || checkoutData.submitting ? (
        <div className="flex w-full items-stretch justify-center px-12 py-10">
          <Spinner />
        </div>
      ) : data.lineItems && data.lineItems.length > 0 ? (
        <>
          <CheckoutErrorList errors={checkoutErrors} />
          <CheckoutForm
            submitText={`${formatCheckoutMessage({
              id: 'pay',
              defaultMessage: 'Pay',
            })}`}
            submitForm={submitForm}
            isFormValid={isItemsListValid(data) && isCheckoutDataValid(checkoutData)}
            isGuestCheckout={!loggedIn}
            addressList={account?.addresses || []}
            billingCountryOptions={billingCountryOptions || []}
            shippingCountryOptions={shippingCountryOptions || billingCountryOptions || []}
            paymentDescriptionList={paymentDescriptionList}
            paymentPreselection={paymentPreselection}
            deliveryTime={deliveryTime}
            paymentB2BDisclaimer={paymentB2BDisclaimer}
          />
        </>
      ) : (
        <EmptyCart cartErrors={checkoutErrors.filter((error) => error instanceof CartError)} />
      )}
    </main>
  );
};

export default Checkout;
