import React from 'react';
import type { Trustbadge } from '@Types/trustedshops/Trustbadge';
import Script from '../../revelo-ui/content/script';

type TrustbadgeProps = {
  trustbadge: Trustbadge;
};

const Trustbadge: React.FC<TrustbadgeProps> = ({ trustbadge }) => {
  return (
    <div>
      <Script
        script={{
          id: 'ts-integration',
          type: 'external',
          asynchronous: true,
          source: `//widgets.trustedshops.com/js/${trustbadge.trustedShopsId}.js`,
          attributes: {
            charSet: trustbadge.charSet ?? 'UTF-8',
            'data-color-scheme': trustbadge.attributes?.colorScheme ?? 'light',
            'data-disable-trustbadge': trustbadge.attributes?.disableTrustbadge ? 'true' : 'false',
            'data-disable-mobile': trustbadge.attributes?.disableMobile ? 'true' : 'false',
            'data-mobile-custom-width': trustbadge.attributes?.mobileCustomWidth ?? '156',
            'data-mobile-disable-reviews': trustbadge.attributes?.mobileDisableReviews ? 'true' : 'false',
            'data-mobile-enable-custom': trustbadge.attributes?.mobileEnableCustom ? 'true' : 'false',
            'data-mobile-enable-fadeout': trustbadge.attributes?.mobileEnableFadeout ? 'true' : 'false',
            'data-mobile-enable-topbar': trustbadge.attributes?.mobileEnableTopbar ? 'true' : 'false',
            'data-mobile-position': trustbadge.attributes?.mobilePosition ?? 'left',
            'data-mobile-y-offset': trustbadge.attributes?.mobileYOffset ?? '0',
            'data-desktop-custom-width': trustbadge.attributes?.desktopCustomWidth ?? '156',
            'data-desktop-disable-reviews': trustbadge.attributes?.desktopDisableReviews ? 'true' : 'false',
            'data-desktop-enable-custom': trustbadge.attributes?.desktopEnableCustom ? 'true' : 'false',
            'data-desktop-enable-fadeout': trustbadge.attributes?.desktopEnableFadeout ? 'true' : 'false',
            'data-desktop-position': trustbadge.attributes?.desktopPosition ?? 'right',
            'data-desktop-y-offset': trustbadge.attributes?.desktopYOffset ?? '0',
          },
        }}
      />
    </div>
  );
};

export default Trustbadge;
