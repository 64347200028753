import React, { FC } from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/solid';
import { TermFacet } from '@Types/result/TermFacet';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import TermFilter from '../../../commercetools-ui/term-filter';
import Typography from '../../../commercetools-ui/typography';
import { getTranslationMap } from '../../products/product-details/attribute/translationMapping';

type TermFilterWrapperProps = {
  facet: TermFacet;
  disabled?: boolean;
  key?: string;
};

const TermFilterDisclosure: FC<TermFilterWrapperProps> = ({ facet, disabled = false }) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const translationMap = getTranslationMap(facet.identifier.replace(/^.+\.attributes\./, ''));

  const label = formatMessage({
    id: translationMap.translationId,
    defaultMessage: translationMap.defaultMessage || facet.label || facet.identifier,
  });

  if (!facet?.terms?.length) return <></>;

  return (
    <div className="pb-6">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between py-1 text-neutral-950 hover:text-neutral-900">
              <span className="text-left font-bold">
                <Typography>{label}</Typography>
              </span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusSmIcon className="size-5" aria-hidden="true" />
                ) : (
                  <PlusSmIcon className="size-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
            <Disclosure.Panel className="pt-6">
              <TermFilter facet={facet} disabled={disabled} />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default TermFilterDisclosure;
