import React from 'react';
import ShoppingCartIcon from 'components/icons/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';

interface CartButtonProps {
  cartItemCount?: number;
  cartLink?: Reference;
}

const CartButton: React.FC<CartButtonProps> = ({ cartItemCount, cartLink }) => {
  //i18n messages
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

  return (
    <div className="ml-2 flow-root">
      <ReferenceLink target={cartLink} className="group relative -m-2 flex items-center p-2">
        <ShoppingCartIcon
          className="size-6 shrink-0 fill-neutral-950 hover:fill-neutral-800 dark:text-light-100 dark:group-hover:text-light-100"
          aria-hidden="true"
        />
        {cartItemCount > 0 && (
          <>
            <span className="absolute right-1 top-1.5 size-2 rounded-full bg-primary-200"></span>
            <span className="sr-only">
              {formatCartMessage({
                id: 'cart.items.in.view',
                defaultMessage: 'items in cart, view cart',
              })}
            </span>
          </>
        )}
      </ReferenceLink>
    </div>
  );
};

export default CartButton;
