import Image, { ImageIntrinsicSize, MediaItemWithMods } from 'frontastic/lib/image';
import { Reference } from '../../../../helpers/reference';
import React, { useState, Fragment } from 'react';
import classNames from 'classnames';
import { Tab } from '@headlessui/react';
import ChevronLeftIcon from 'components/icons/chevron-left';
import ChevronRightIcon from 'components/icons/chevron-right';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import Button from '../button';

interface TextImageSliderProps {
  imagePosition: 'positionLeft' | 'positionRight';
  containerWidth: number;
  containerBackgroundColor?: string;
  textColor?: string;
  slides: Array<{
    id: number;
    headline: string;
    subline: string;
    enableBtn?: boolean;
    buttonLink?: Reference;
    buttonLabel?: string;
    buttonType: 'primary' | 'accent' | 'secondary' | 'hollow';
    image: MediaItemWithMods;
    intrinsicSize?: ImageIntrinsicSize;
  }>;
}

const TextImageSlider: React.FC<TextImageSliderProps> = ({
  imagePosition,
  containerWidth,
  containerBackgroundColor,
  textColor,
  slides,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isDesktopSize] = useMediaQuery(tablet);
  const imageWidth = 100 - containerWidth;

  return (
    <div
      className={classNames('flex flex-wrap', {
        'flex-row-reverse': imagePosition === 'positionRight',
      })}
    >
      <div
        className="w-full"
        style={{
          backgroundColor: containerBackgroundColor || 'unset',
          width: `${imageWidth > 0 && isDesktopSize ? imageWidth : '100'}%`,
        }}
      >
        <Tab.Group selectedIndex={selectedIndex}>
          <Tab.List className="hidden w-full gap-3">
            {slides.map((slide, index) => (
              <Tab as={Fragment} key={slide.id}>
                <button
                  className={classNames('cursor-pointer', {
                    'font-semibold': selectedIndex === index,
                  })}
                >
                  0{slide.id + 1}
                </button>
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            {slides.map(({ id, image, intrinsicSize }, index) => (
              <Tab.Panel key={id} unmount={false}>
                <div>
                  <Image media={image.media} style={{ objectFit: 'contain' }} intrinsicSize={+intrinsicSize} />
                </div>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>

      <div
        style={{
          backgroundColor: containerBackgroundColor || 'unset',
          color: textColor || 'unset',
          width: `${containerWidth > 0 && isDesktopSize ? containerWidth : '100'}%`,
        }}
        className={classNames(`w-full px-4 pb-8 pt-6 lg:pl-32 lg:pr-20 lg:pt-20`, {})}
      >
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <div className="flex w-full flex-col gap-10">
            <div className="flex flex-wrap gap-8 lg:gap-24">
              <Tab.List className="flex w-full gap-3">
                {slides.map((slide, index) => (
                  <Tab as={Fragment} key={slide.id}>
                    <button
                      className={classNames('cursor-pointer', {
                        'font-semibold': selectedIndex === index,
                      })}
                    >
                      0{slide.id + 1}
                    </button>
                  </Tab>
                ))}
              </Tab.List>
              <div className="flex w-full gap-4">
                <button
                  className={classNames('flex items-center justify-center rounded-full p-2', {
                    'bg-neutral-950/5': selectedIndex === 0,
                    'bg-neutral-950/10 hover:bg-accent-300': selectedIndex !== 0,
                  })}
                  disabled={selectedIndex === 0}
                  onClick={() => setSelectedIndex(selectedIndex - 1)}
                >
                  <ChevronLeftIcon
                    className={classNames('size-6', {
                      'fill-neutral-950/50': selectedIndex === 0,
                      'fill-neutral-950': selectedIndex !== 0,
                    })}
                  />
                </button>
                <button
                  className={classNames('flex items-center justify-center rounded-full p-2', {
                    'bg-neutral-950/5': selectedIndex === slides.length - 1,
                    'bg-neutral-950/10 hover:bg-accent-300': selectedIndex !== slides.length - 1,
                  })}
                  disabled={selectedIndex === slides.length - 1}
                  onClick={() => setSelectedIndex(selectedIndex + 1)}
                >
                  <ChevronRightIcon
                    className={classNames('size-6', {
                      'fill-neutral-950/50': selectedIndex === slides.length - 1,
                      'fill-neutral-950': selectedIndex !== slides.length - 1,
                    })}
                  />
                </button>
              </div>
            </div>
            <Tab.Panels>
              {slides.map(({ id, headline, subline, enableBtn, buttonLink, buttonLabel, buttonType }, index) => (
                <Tab.Panel key={id} unmount={false}>
                  <div className="flex flex-wrap gap-6">
                    <div className="font-headline text-5xl lg:text-6xl">{headline}</div>
                    {subline && <div className="text-base lg:text-lg">{subline}</div>}
                    {enableBtn && (
                      <div>
                        <Button style={buttonType} reference={buttonLink}>
                          <span className="flex items-center justify-center gap-2">{buttonLabel}</span>
                        </Button>
                      </div>
                    )}
                  </div>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
    </div>
  );
};

export default TextImageSlider;
