import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg width="24" height="24" className={className} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <polygon points="15 21.4 5.6 12 15 2.6 16.4 4 8.4 12 16.4 20 15 21.4" />
  </svg>
);

export default Icon;
