import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="24" height="24" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4,21.9c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7,2.7,1.2,2.7,2.7-1.2,2.7-2.7,2.7ZM18.4,18.5c-.4,0-.7.3-.7.7s.3.7.7.7.7-.3.7-.7-.3-.7-.7-.7Z" />
    <path d="M8.1,21.9c-1.5,0-2.7-1.2-2.7-2.7s1.2-2.7,2.7-2.7,2.7,1.2,2.7,2.7-1.2,2.7-2.7,2.7ZM8.1,18.5c-.4,0-.7.3-.7.7s.3.7.7.7.7-.3.7-.7-.3-.7-.7-.7Z" />
    <path d="M6.9,14.2c.1.5.7.9,1.2.7.5-.1.9-.7.7-1.2l-1.9.5ZM5,3.6l1-.3-.2-.7h-.8v1ZM2.1,2.6h-1v2h1v-2ZM8.8,13.7L6,3.3l-1.9.5,2.8,10.4,1.9-.5ZM5,2.6h-3v2h3v-2Z" />
    <path d="M18.4,18.5H6.8c-.7,0-1.4-.3-1.9-.8-.5-.5-.8-1.2-.8-1.9,0-.4,0-.7.2-1,.1-.3.3-.6.6-.9.3-.3.6-.5.9-.6.3-.1.7-.2,1-.2h11.6l1.6-6H5.7c-.6,0-1-.4-1-1s.4-1,1-1h14.2c.3,0,.6,0,.9.2.3.1.5.3.7.6.2.2.3.5.4.8,0,.3,0,.6,0,.9l-1.6,6c-.1.4-.4.8-.7,1.1-.3.3-.8.4-1.2.4H6.8c0,0-.2,0-.3,0,0,0-.2,0-.2.2,0,0-.1.1-.2.2,0,0,0,.2,0,.3,0,.2,0,.4.2.5s.3.2.5.2h11.6c.6,0,1,.4,1,1s-.4,1-1,1Z" />
  </svg>
);

export default Icon;
