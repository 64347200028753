import React, { useEffect, useState } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import classnames from 'classnames';
import { updateURLParams } from '../../../helpers/utils/updateURLParams';
import ChevronLeftIcon from '../../icons/chevron-left';
import ChevronRightIcon from '../../icons/chevron-right';

type Props = {
  previousCursor?: string;
  nextCursor?: string;
  navClassNames?: string;
};

const Pagination: React.FC<Props> = ({ previousCursor, nextCursor, navClassNames }) => {
  const router = useRouter();

  const [previousPageURL, setPreviousPageURL] = useState<string>('/');
  const [nextPageURL, setNextPageURL] = useState<string>('/');

  useEffect(() => {
    if (previousCursor) {
      setPreviousPageURL(updateURLParams([{ key: 'cursor', value: previousCursor }], true, ['cursor']));
    }

    if (nextCursor) {
      setNextPageURL(updateURLParams([{ key: 'cursor', value: nextCursor }], true, ['cursor']));
    }
  }, [previousCursor, nextCursor, router.query]);

  if (!previousCursor && !nextCursor) {
    return null;
  }

  return (
    <nav className={classnames(`flex items-center justify-center py-3`, navClassNames)} aria-label="Pagination">
      <div className="flex items-center justify-between gap-10">
        <NextLink
          href={previousPageURL}
          aria-label="Previous Page"
          className={classnames('flex size-10 items-center justify-center rounded-full', {
            'pointer-events-none cursor-not-allowed bg-neutral-950/5': !previousCursor,
            'bg-neutral-950/10': previousCursor,
          })}
        >
          <ChevronLeftIcon
            className={classnames('size-6 text-neutral-950', {
              'opacity-50': !previousCursor,
            })}
          />
        </NextLink>
        <NextLink
          href={nextPageURL}
          aria-label="Next Page"
          className={classnames('flex size-10 items-center justify-center rounded-full', {
            'pointer-events-none cursor-not-allowed bg-neutral-950/5': !nextCursor,
            'bg-neutral-950/10': nextCursor,
          })}
        >
          <ChevronRightIcon
            className={classnames('size-6 text-neutral-950', {
              'opacity-50': !nextCursor,
            })}
          />
        </NextLink>
      </div>
    </nav>
  );
};

export default Pagination;
