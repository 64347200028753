import React from 'react';

type Props = {
  className?: string;
  stroke?: string;
};

const Icon: React.FC<Props> = ({ className, stroke }: Props) => (
  <svg className={className} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2411 3.49111L12.3661 2.36612C12.8543 1.87796 13.6457 1.87796 14.1339 2.36612C14.622 2.85427 14.622 3.64573 14.1339 4.13388L7.05479 11.213C6.70234 11.5654 6.26762 11.8245 5.78993 11.9668L4 12.5L4.53319 10.7101C4.67548 10.2324 4.93456 9.79767 5.28701 9.44522L11.2411 3.49111ZM11.2411 3.49111L13 5.24999M12 9.83333V13C12 13.8284 11.3284 14.5 10.5 14.5H3.5C2.67157 14.5 2 13.8284 2 13V5.99999C2 5.17157 2.67157 4.49999 3.5 4.49999H6.66667"
      stroke={stroke ?? '#0F4A48'}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Icon;
