import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M12,1C5.9,1,1,5.9,1,12s4.9,11,11,11,11-4.9,11-11S18.1,1,12,1ZM12,21c-5,0-9-4-9-9S7,3,12,3s9,4,9,9-4,9-9,9Z" />
    <polygon points="10.9 13.1 8 10.3 6.6 11.7 10.9 15.9 17.4 9.5 16 8.1 10.9 13.1" />
  </svg>
);

export default Icon;
