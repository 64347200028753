import React from 'react';
import classnames from 'classnames';
import { Reference, ReferenceLink } from '../../../../helpers/reference';

type Props = {
  type?: 'submit' | 'reset' | 'button';
  style: 'primary' | 'accent' | 'secondary' | 'hollow';
  size?: 'small' | 'medium' | 'large';
  children: React.ReactNode;
  reference?: Reference;
  onClick?: (data?: any) => void;
  className?: string;
  referenceLinkClassName?: string;
  disabled?: boolean;
  fullWidth?: boolean;
};

const Button: React.FC<Props> = ({
  type = 'button',
  style,
  size = 'large',
  children,
  reference,
  onClick,
  className,
  referenceLinkClassName,
  disabled = false,
  fullWidth = false,
}) => {
  const button = (
    <button
      type={type}
      className={classnames(
        className,
        'min-w-[250px] items-center justify-center rounded-4xl border-2 font-semibold transition duration-150 ease-out disabled:bg-neutral-200 disabled:text-neutral-500',
        {
          'border-transparent bg-primary-200 text-neutral-950 hover:bg-primary-100 focus:bg-primary-100':
            style === 'primary',
          'border-transparent bg-accent-300 text-neutral-950 hover:bg-accent-200 focus:bg-accent-500 focus:ring-accent-400 disabled:bg-accent-100':
            style === 'accent',
          'border-transparent bg-neutral-950 text-white hover:bg-neutral-900 focus:bg-neutral-900':
            style === 'secondary',
          'border-neutral-950 bg-transparent text-neutral-950 hover:bg-neutral-950/10 focus:bg-neutral-950/10 disabled:border-neutral-200 disabled:bg-transparent':
            style === 'hollow',
          'w-full': fullWidth,
          'px-8 py-3 text-md': size === 'large',
          'px-6 py-2 text-base': size === 'medium',
          'px-6 py-2 text-sm': size === 'small',
        },
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );

  if (reference) {
    return (
      <ReferenceLink target={reference} className={referenceLinkClassName}>
        {button}
      </ReferenceLink>
    );
  }

  return button;
};

export default Button;
