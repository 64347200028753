import React from 'react';
import { AttributeGroup } from '@Types/product/AttributeGroup';
import { Variant } from '@Types/product/Variant';
import classnames from 'classnames';
import InformationCircleIcon from 'components/icons/informaction-circle';
import { Reference, ReferenceLink } from '../../../../../helpers/reference';
import BookOpen from '../../../../icons/book-open';
import Overlay from '../../../content/overlay';
import Attribute from '../attribute';

export interface Props {
  variant: Variant;
  groups: AttributeGroup[];
  isExpanded?: boolean;
}

type DisplayGroup = {
  title: string;
  attributes: {
    attributeId: string;
    attributeValue: any;
  }[];
  tooltipContent: string;
  attributeLinkText?: string;
  attributeLink?: Reference;
};

export default function VariantAttributes({ variant, groups, isExpanded = false }: Props) {
  const attributeGroups: DisplayGroup[] = groups
    .map((group: AttributeGroup) => ({
      title: group.title,
      attributes: group.attributes
        .map((attributeId: string) => ({
          attributeId: attributeId,
          attributeValue: variant.attributes?.[attributeId],
        }))
        .filter(({ attributeValue }) => !!attributeValue),
      tooltipContent: group.tooltipContent,
      attributeLinkText: group.attributeLinkText,
      attributeLink: group.attributeLink,
    }))
    .filter((group: DisplayGroup) => group.attributes.length > 0);

  const triggerIcon: React.JSX.Element = <InformationCircleIcon className="size-6 text-neutral-800" />;

  return (
    <div
      className={classnames('prose-sm', {
        'max-h-[500px] overflow-hidden': !isExpanded,
      })}
    >
      {attributeGroups.map((group: DisplayGroup, index: number) => (
        <div className="mb-3" key={`attribute-groups-${index}`}>
          <div className="mb-2 flex items-center bg-neutral-100 px-2 py-1 font-bold text-neutral-950">
            {group.title}
            {group.tooltipContent && (
              <Overlay
                idSelector={group.title.replace(/\s/g, '')}
                wrapperClassNames="flex ml-3"
                text={group.tooltipContent}
                triggerType="buttonClick"
                triggerText={triggerIcon}
                maxHeight="80%"
              />
            )}
          </div>
          {group.attributes.map(({ attributeId, attributeValue }, index: number) => (
            <div key={index} className="grid w-full grid-cols-2 px-2 py-1 text-neutral-950">
              <Attribute attributeId={attributeId} value={attributeValue} />
            </div>
          ))}
          {group.attributeLink && group.attributeLinkText?.length > 0 && (
            <div className="flex gap-2 px-2 py-1">
              <BookOpen className="text-primary-300" />
              <ReferenceLink target={group.attributeLink} className="text-xs text-secondary-600">
                {group.attributeLinkText}
              </ReferenceLink>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
