import React, { useRef } from 'react';
import Script from 'next/script';
import ChevronRightIcon from '../../../icons/chevron-right';
import QuestionmarkFilledIcon from '../../../icons/questionmark-filled';

interface Props {
  srcLink: string;
  configUrl: string;
  showButton?: boolean;
  buttonText?: string;
}

export const landbot = { current: null };

const LiveChat: React.FC<Props> = ({ srcLink, configUrl, showButton, buttonText }) => {
  const setUpLandbot = () => {
    // @ts-ignore
    landbot.current = new Landbot.Livechat({
      configUrl: configUrl,
    });
  };

  const handleLandbotClick = () => {
    landbot?.current.open();
  };

  return (
    <>
      {showButton && landbot.current && (
        <button
          onClick={handleLandbotClick}
          className="flex items-center justify-between gap-2 rounded-4xl border bg-neutral-100 px-4 py-3 text-md text-neutral-900 shadow-sm hover:border-primary-100"
        >
          <div className="flex items-center gap-2">
            <QuestionmarkFilledIcon className="size-7 text-neutral-900" aria-hidden="true" />
            {buttonText}
          </div>
          <ChevronRightIcon className="size-6 text-neutral-900" aria-hidden="true" />
        </button>
      )}
      <Script src={srcLink} onLoad={setUpLandbot} />
    </>
  );
};

export default LiveChat;
