import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
    <path d="M19.5,5v-3h-2v3H6.5v-3h-2v3H1v17h22V5h-3.5ZM21,20H3v-8h18v8ZM3,10v-3h18v3H3Z" />
    <rect x="5" y="14" width="4" height="4" />
  </svg>
);

export default Icon;
