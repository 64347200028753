import { Trans, useTranslation } from 'react-i18next';
import { Reference, ReferenceLink } from '../../../../../helpers/reference';

export interface Props {
  className?: string;
  linkClassName?: string;
  termsLink?: Reference;
  cancellationLink?: Reference;
  privacyLink?: Reference;
}

const Disclaimer = ({ className, linkClassName, termsLink, cancellationLink, privacyLink }: Props) => {
  const { t } = useTranslation(['checkout']);
  const defaultLinkClass = 'cursor-pointer text-secondary-600 hover:underline';

  const getDefaultLink = (path: string): Reference => {
    return {
      type: 'link',
      link: path,
      openInNewWindow: false,
    };
  };

  const interpolatedComponents = [
    <ReferenceLink
      key={0}
      className={linkClassName || defaultLinkClass}
      target={termsLink || getDefaultLink('/agb')}
    />,
    <ReferenceLink
      key={1}
      className={linkClassName || defaultLinkClass}
      target={cancellationLink || getDefaultLink('/agb')}
    />,
    <ReferenceLink
      key={2}
      className={linkClassName || defaultLinkClass}
      target={privacyLink || getDefaultLink('/datenschutz')}
    />,
  ];

  return (
    <div className={className || 'px-1 py-5 text-center text-xs'}>
      <Trans i18nKey="disclaimer" t={t} components={interpolatedComponents} />
    </div>
  );
};

export default Disclaimer;
