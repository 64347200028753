import { AvailableSize } from '@Types/product/AvailableSize';
import { Variant } from '@Types/product/Variant';
import { FilterUtils } from './FilterUtils';

export class VariantSortUtil {
  private static readonly SIZE_ATTRIBUTE = 'frame_height_manufacturer';

  public static getWeightedValue(variant: Variant) {
    const frameHeightAttribute = VariantSortUtil.getSize(variant);
    const mileageAttribute = variant.attributes['mileage_in_km']?.[0];
    const type = frameHeightAttribute.toLowerCase().includes('cm')
      ? 'cm'
      : frameHeightAttribute.toLowerCase().includes('"')
      ? 'in'
      : frameHeightAttribute.toUpperCase();

    const weight = FilterUtils.sizeSortMapping[type];
    let mileage = parseInt(mileageAttribute);
    if (isNaN(mileage)) {
      mileage = 0;
    }

    if (weight) {
      return { weight, value: type === 'cm' || type === 'in' ? parseInt(type, 10) : undefined, mileage: mileage };
    }
    return undefined;
  }

  public static sortVariants(a: Variant, b: Variant): number {
    const aMap = VariantSortUtil.getWeightedValue(a);
    const bMap = VariantSortUtil.getWeightedValue(b);

    if (aMap && bMap) {
      if (aMap.weight === bMap.weight) {
        if (aMap.value === bMap.value) {
          return aMap.mileage - bMap.mileage;
        }

        if (aMap.value && bMap.value) {
          return aMap.value - bMap.value;
        }

        return bMap.value === undefined ? -1 : 1;
      }

      return aMap.weight - bMap.weight;
    }

    if (aMap) {
      return -1;
    }

    if (bMap) {
      return 1;
    }
  }

  public static getAvailableSizes(variants: Variant[]): AvailableSize[] {
    const availableSizes: AvailableSize[] = [];

    for (const variant of variants) {
      const size = VariantSortUtil.getSize(variant);
      if (size !== undefined) {
        const available = availableSizes.find((availableSize) => availableSize.size === size);

        if (!available) {
          availableSizes.push({
            size,
            sku: variant.sku,
            count: 1,
          });
        } else {
          available.count += 1;
        }
      }
    }
    return availableSizes;
  }

  public static getSize(variant: Variant): string | undefined {
    return variant.attributes[VariantSortUtil.SIZE_ATTRIBUTE][0];
  }
}
