import React from 'react';
import { RegisterOptions, useFormContext } from 'react-hook-form';
import { getFieldError } from '../../utils/FormFieldUtils';
import ErrorElement from '../form-elements/ErrorElement';
import InfoElement from '../form-elements/InfoElement';
import LabelElement from '../form-elements/LabelElement';
import SelectElement from '../form-elements/SelectElement';

interface Props {
  readonly name: string;
  readonly label?: string | React.JSX.Element;
  readonly smallLabel?: string | React.JSX.Element;
  readonly formId?: string;
  readonly options: { display: string; data: string; disabled?: boolean }[];
  readonly selectedOptionValue: string;
  readonly onChange: (propName: string, newValue: string) => void;
  readonly containerClassNames?: string;
  readonly labelClassNames?: string;
  readonly smallLabelClassNames?: string;
  readonly selectContainerClassNames?: string;
  readonly selectClassNames?: string;
  readonly validation?: RegisterOptions;
  readonly inverseLabel?: boolean;
  readonly infoText?: string;
}

const FormFieldSelect = ({
  name,
  label,
  smallLabel,
  formId,
  options,
  selectedOptionValue,
  onChange,
  containerClassNames,
  labelClassNames,
  smallLabelClassNames,
  selectContainerClassNames,
  selectClassNames,
  validation,
  inverseLabel,
  infoText,
}: Props) => {
  const {
    formState: { errors },
  } = useFormContext();

  const fieldError = getFieldError(name, errors);

  const selectElement = (
    <SelectElement
      name={name}
      containerClassNames={selectContainerClassNames}
      classNames={selectClassNames}
      options={options}
      value={selectedOptionValue}
      onChange={onChange}
      validation={validation}
    />
  );

  const labelElement = (
    <LabelElement
      fieldId={name}
      formId={formId}
      label={label}
      smallLabel={smallLabel}
      classNames={labelClassNames || 'block text-neutral-700 dark:text-light-100'}
      smallLabelClassNames={smallLabelClassNames}
    />
  );

  const errorElement = <ErrorElement error={fieldError} fieldId={name} />;
  const infoElement = <InfoElement infoText={infoText} classNames="block text-neutral-700 text-sm pt-2" />;

  return (
    <div className={containerClassNames ? containerClassNames : 'col-span-full'}>
      {inverseLabel && selectElement}
      {labelElement}
      {!inverseLabel && selectElement}
      {fieldError ? errorElement : infoElement}
    </div>
  );
};

export default FormFieldSelect;
