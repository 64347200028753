import React from 'react';

interface Props {
  readonly onClick: (data?: any) => void;
  readonly name?: string;
  readonly buttonText: string;
  readonly isDisabled?: boolean;
  readonly containerClassNames?: string;
  readonly classNames?: string;
}

const ButtonElement = ({ onClick, name, buttonText, isDisabled, containerClassNames, classNames }: Props) => {
  return (
    <div className={containerClassNames ? containerClassNames : 'mt-2'}>
      <button
        id={name}
        name={name}
        className={
          classNames ||
          'w-full min-w-[250px] items-center justify-center rounded-4xl border-2 border-transparent bg-neutral-950 px-8 py-3 text-md font-semibold text-white transition duration-150 ease-out hover:bg-neutral-900 focus:bg-neutral-900 disabled:bg-neutral-200 disabled:text-neutral-500'
        }
        disabled={isDisabled}
        onClick={(e: React.FormEvent) => {
          e.preventDefault();
          onClick();
        }}
      >
        {buttonText}
      </button>
    </div>
  );
};

export default ButtonElement;
