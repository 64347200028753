import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M22,5h-5v-1c0-.8-.3-1.6-.9-2.1s-1.3-.9-2.1-.9h-4c-.8,0-1.6.3-2.1.9s-.9,1.3-.9,2.1v1H2v2h2v13c0,.8.3,1.6.9,2.1.6.6,1.3.9,2.1.9h10c.8,0,1.6-.3,2.1-.9.6-.6.9-1.3.9-2.1V7h2v-2ZM9,4c0-.3.1-.5.3-.7s.4-.3.7-.3h4c.3,0,.5.1.7.3s.3.4.3.7v1h-6v-1ZM18,20c0,.3-.1.5-.3.7-.2.2-.4.3-.7.3H7c-.3,0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7V7h12v13Z" />
  </svg>
);

export default Icon;
