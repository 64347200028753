import React from 'react';
import { useRouter } from 'next/router';
import type { NotificationList } from '@Types/notification';
import { useFormat } from '../../../../helpers/hooks/useFormat';

export type NotificationListProps = {
  notifications: NotificationList;
};

const NotificationList = ({ notifications }: NotificationListProps) => {
  const { formatMessage } = useFormat({ name: 'account' });
  const router = useRouter();

  return (
    <main className="flex flex-col lg:flex-row">
      <div className="mx-auto flex-auto px-4 py-5 sm:px-6 lg:px-8 lg:py-32">
        <p className="mt-3 text-3xl font-extrabold tracking-tight text-red-700">
          {formatMessage({
            id: `notificationList`,
            defaultMessage: `Notifications - `,
          })}{' '}
          {notifications.email ?? 'n/a'}
        </p>

        <div className="mt-3 border-t border-gray-200 py-6">
          <ul>
            {notifications.notifications.map((notification, index) => (
              <li key={`notification-${index}`} className="mb-2.5">
                <div
                  className="cursor-pointer font-medium text-gray-800 hover:text-gray-500"
                  onClick={() => router.push(notification.resultUrl)}
                >
                  {notification.title}
                  {!notification.active && <span className="ml-1.5 text-xs text-red-700">deactivated</span>}
                </div>
                <div className="ml-5 text-xs">{JSON.stringify(notification)}</div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </main>
  );
};

export default NotificationList;
