import { LineItem } from '@Types/cart/LineItem';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { CartItemError as BaseError } from '../../cart/errors/CartItemError';

export interface Props {
  readonly error: BaseError;
}

const CartItemError = ({ error }: Props) => {
  const { formatMessage } = useFormat({ name: 'cart' });

  return (
    <section aria-labelledby="cart-item-error" className="flex-auto overflow-y-auto px-0 pb-4 pt-0">
      <div className="pt-2 text-sm">
        {formatMessage({
          id: 'cartItem.error.missingStock',
          defaultMessage: 'Insufficient stock',
        })}
      </div>
      <div className="pl-4 pt-2 text-sm">
        <ul>
          {error.items.map((item: LineItem, index) => (
            <li key={`cart-item-error-${index}`} className="">
              {formatMessage({
                id: 'cartItem.error.missingStockItem',
                defaultMessage: `${item.name}`,
                values: {
                  productName: item.name,
                  availableQuantity: item.variant?.availableQuantity || '',
                },
              })}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default CartItemError;
