import { useFormat } from '../../../../helpers/hooks/useFormat';
import { DiscountError as BaseError } from '../../cart/errors/DiscountError';

export interface Props {
  readonly error: BaseError;
}

const DiscountError = ({ error }: Props) => {
  const { formatMessage } = useFormat({ name: 'cart' });

  return (
    <section aria-labelledby="cart-item-error" className="flex-auto overflow-y-auto px-0 pb-4 pt-0">
      <div className="border-y border-red-500 bg-red-100 px-4 py-3 text-red-700" role="alert">
        <div className="font-bold">
          {formatMessage({
            id: `cartDiscount.error.${error.message[0].toLowerCase()}${error.message.substring(1)}`,
            defaultMessage: `cartDiscount.error.${error.message[0].toLowerCase()}${error.message.substring(1)}`,
          })}
        </div>
        <div className="pl-4 pt-2 text-sm">
          <ul>
            {error.getDiscounts().map((discount, index) => (
              <li key={`cart-discount-error-${index}`} className="">
                <span className="pr-2">{discount.code}</span>-
                <span className="pl-2">
                  {formatMessage({
                    id: `cart.discount.${discount.state[0].toLowerCase()}${discount.state.substring(1)}`,
                    defaultMessage: formatMessage({
                      id: 'cart.discount.error',
                      values: { error: error.message },
                      defaultMessage: error.message,
                    }),
                  })}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default DiscountError;
