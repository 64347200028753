import React from 'react';
import { Tooltip } from 'react-tooltip';
import InformationCircleIcon from 'components/icons/informaction-circle';
import Markdown from '../content/markdown';
import { Tooltip as TooltipDetails } from '../types/Tooltip';

interface TooltipProps {
  tooltip: TooltipDetails;
  children?: React.ReactNode;
  wrapperClassNames?: string;
  iconClassNames?: string;
  contentClassNames?: string;
}

const IconTooltip: React.FC<TooltipProps> = ({
  tooltip,
  children,
  wrapperClassNames,
  iconClassNames,
  contentClassNames,
}) => {
  const tooltipId = `tooltip-${tooltip.type}`;

  const openEvents = {
    mouseenter: true,
    focus: true,
    click: true,
  };

  const closeEvents = {
    mouseleave: true,
    blur: true,
    click: true,
  };

  return (
    <div className={`revelo-tooltip-container ${wrapperClassNames}`}>
      <a id={tooltipId} className="hover:cursor-pointer">
        {tooltip.icon ? (
          <div className={iconClassNames}>{tooltip.icon}</div>
        ) : (
          <InformationCircleIcon className={iconClassNames ?? `size-5 text-primary-400`} />
        )}
      </a>
      <Tooltip
        anchorSelect={`#${tooltipId}`}
        clickable={true}
        className="revelo-tooltip"
        classNameArrow="revelo-tooltip-arrow"
        place={tooltip.styling?.position ?? 'right'}
        opacity={tooltip.styling?.opacity ?? 1.0}
        border={tooltip.styling?.border ?? '1px solid #0F4A48'}
        offset={tooltip.styling?.offset ?? 10}
        openEvents={openEvents}
        closeEvents={closeEvents}
      >
        {tooltip.content && typeof tooltip.content === 'string' ? (
          <Markdown text={tooltip.content} className={contentClassNames} />
        ) : (
          <div className={contentClassNames}>{tooltip.content ?? children}</div>
        )}
      </Tooltip>
    </div>
  );
};

export default IconTooltip;
