import React from 'react';
import ReactDOM from 'react-dom/client';

const measureElement = async (element: React.JSX.Element) => {
  const container = document.createElement('div');
  container.style.display = 'inline-block';
  container.style.position = 'absolute';
  container.style.visibility = 'hidden';
  container.style.zIndex = '-1';
  document.body.appendChild(container);

  const root = ReactDOM.createRoot(container);

  await new Promise<void>((resolve) => {
    // @ts-ignore
    root.render(React.createElement(React.Fragment, null, React.cloneElement(element, { onRendered: resolve })));
  });

  const height = container.clientHeight;
  const width = container.clientWidth;

  root.unmount();
  container.remove();

  return {
    height,
    width,
  };
};

export default measureElement;
