import { FormEvent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Cart as CartType, LineItem, PaymentLogos } from '@Types/cart';
import { CartError as CartErrorType } from '@Types/cart/CartError';
import { mutate } from 'swr';
import { useCheckout } from '@Provider/revelo-checkout';
import Spinner from 'components/commercetools-ui/spinner';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference } from 'helpers/reference';
import { NextFrontasticImage } from 'frontastic/lib/image';
import CartForm from './cart-form';
import EmptyCart from './empty-cart';
import { CartError } from './errors/CartError';
import { useAccount } from '../../../frontastic';
import { updateItemShippingMethods } from '../../../frontastic/actions/cart';
import { CartErrorUtils } from '../utils/CartErrorUtils';

export interface Props {
  pageTitle?: string;
  emptyStateImage?: NextFrontasticImage;
  emptyStateTitle?: string;
  emptyStateSubtitle?: string;
  emptyStateCTALabel?: string;
  emptyStateCTALink?: Reference;
  cart: CartType;
  cartErrors?: Array<CartError | CartErrorType>;
  editItemQuantity: (lineItemId: string, newQuantity: number) => Promise<void>;
  removeItem: (lineItemId: string) => void;
  paymentLogos: PaymentLogos[];
  defaultShippingCountry?: string;
  deliveryTime?: string;
}

const Cart = ({
  cart,
  cartErrors,
  editItemQuantity,
  removeItem,
  pageTitle,
  emptyStateImage,
  emptyStateTitle,
  emptyStateSubtitle,
  emptyStateCTALabel,
  emptyStateCTALink,
  paymentLogos,
  defaultShippingCountry = 'DE',
  deliveryTime,
}: Props) => {
  const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
  const {
    recalculationRequired,
    calculatingShipping,
    setIsCalculatingShipping,
    setRecalculationRequired,
    setLastCheckoutError,
  } = useCheckout();
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(true);
  const { loggedIn, account } = useAccount();

  const onCheckout = (e: FormEvent) => {
    e.preventDefault();
    router.push('/checkout');
  };

  const goToProductPage = (_url: string) => router.push(_url);

  const getItemShippingAddress = (item: LineItem) => {
    if (!loggedIn) {
      return item.shippingDetails?.shippingAddress ?? cart.shippingAddress;
    }

    const addressId = cart.shippingAddress?.addressId;
    if (addressId) {
      return account.addresses.find((address) => address.addressId === addressId) || null;
    }

    return null;
  };

  const cartErrorList = CartErrorUtils.getCartErrorList(cartErrors ?? []);

  useEffect(() => {
    if (cart?.cartId !== undefined) {
      setLoading(false);
    }
  }, [cart]);

  useEffect(() => {
    if (!recalculationRequired || calculatingShipping || !cart?.lineItems?.length) {
      return;
    }

    try {
      setIsCalculatingShipping(true);
      const updateShippingDetails = async () => {
        await updateItemShippingMethods(
          cart.lineItems.map((item) => ({
            ...item,
            shippingDetails: {
              shippingMethodId: item.shippingDetails?.shippingInfoId,
              shippingAddress: getItemShippingAddress(item),
            },
          })),
        );
      };

      updateShippingDetails().then(() => {
        setRecalculationRequired(false);
        setIsCalculatingShipping(false);
        mutate('/action/cart/getCart');
      });
    } catch (error) {
      setLastCheckoutError(error);
      setRecalculationRequired(false);
    }
  }, [recalculationRequired]);

  if (loading) {
    return (
      <div className="flex items-stretch justify-center px-12 py-20">
        <Spinner />
      </div>
    );
  }

  if ((!loading && !cart?.lineItems) || cart.lineItems.length < 1) {
    return (
      <EmptyCart
        pageTitle={pageTitle}
        image={emptyStateImage}
        title={emptyStateTitle}
        subtitle={emptyStateSubtitle}
        ctaLabel={emptyStateCTALabel}
        ctaLink={emptyStateCTALink}
        cartErrors={cartErrorList}
      />
    );
  }

  return (
    <main className="mx-auto max-w-2xl pb-24 pt-8 sm:px-4 lg:max-w-7xl lg:px-0">
      <div className="lg:grid lg:grid-cols-12 lg:gap-x-10">
        <div className="flex flex-wrap pb-2 lg:col-span-8">
          <h1 className="flex w-full font-headline text-5xl text-neutral-950 sm:text-6xl">
            {formatCartMessage({ id: 'cart.shopping', defaultMessage: 'Shopping Cart' })}
          </h1>
          <div className="mt-4 flex w-full justify-between">
            <div className="text-sm">
              {formatCartMessage({
                id: 'cart.shopping.unique',
                defaultMessage: 'Attention, unique item - wheels are not reserved',
              })}
            </div>
            <div className="shrink-0 text-sm">
              {cart.lineItems.length}{' '}
              {cart.lineItems.length > 1
                ? formatCartMessage({ id: 'cart.shopping.itemsLabel', defaultMessage: 'Items' })
                : formatCartMessage({ id: 'cart.shopping.itemLabel', defaultMessage: 'Item' })}
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="flex items-stretch justify-center px-12 py-10">
          <Spinner />
        </div>
      ) : (
        <CartForm
          cart={cart}
          cartErrors={cartErrorList}
          submitForm={onCheckout}
          goToProductPage={goToProductPage}
          editItemQuantity={editItemQuantity}
          removeItem={removeItem}
          paymentLogos={paymentLogos}
          defaultShippingCountry={defaultShippingCountry}
          deliveryTime={deliveryTime}
        />
      )}
    </main>
  );
};

export default Cart;
