import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import { XIcon } from '@heroicons/react/outline';
import { createPortal } from 'react-dom';
import Slider, { Slide, SliderProps } from 'components/revelo-ui/slider';
import imageUrlResize from 'helpers/imageUrlResize';

export interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}

export interface Props {
  images: UIImage[];
  activeIndex: number;
  onClose: () => void;
}

const FullScreenModal = ({ images, activeIndex, onClose }: Props) => {
  const sliderConfiguration: SliderProps = {
    slidesPerView: 1,
    thumbChildren: images.map((image, index) => (
      <Image
        loader={({ src }) => src}
        fill
        src={imageUrlResize(image.src, 'small')}
        alt={image.alt}
        className="w-full cursor-pointer object-center"
        key={`zoom-image--${index}`}
        style={{
          objectFit: 'contain',
        }}
      />
    )),
    arrows: true,
    dots: false,
    withThumbs: true,
    fitToSlides: true,
    zoom: 'ontouchstart' in window || navigator.maxTouchPoints > 0,
    sliderVersion: 'v2',
    initialSlide: activeIndex,
    onClick: (swiper) => {
      const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;

      if (!isTouchDevice) {
        swiper.zoom.enable();
        swiper.zoom.toggle();
      }
    },
    onSlideChange: (swiper) => {
      if (swiper.zoom.scale > 1) {
        swiper.zoom.out();
      }
    },
  };

  return createPortal(
    <div className="modal-fullscreen-image fixed inset-0 z-90 bg-white backdrop-blur-sm">
      <button
        onClick={onClose}
        className="absolute right-0 top-0 z-40 rounded-full p-4 text-gray-500 hover:bg-[#f4f6f6]"
      >
        <XIcon className="size-8" aria-hidden="true" />
      </button>

      <Slider {...sliderConfiguration} key={images[0].id}>
        {images?.map((image, index) => (
          <Slide key={`${image.id}${index}`}>
            <div className="swiper-zoom-container">
              <Image
                loader={({ src }) => src}
                fill
                src={imageUrlResize(image.src, 'original')}
                alt={image.alt}
                className="w-full cursor-zoom-in object-center"
                key={`zoom-image--${index}`}
                style={{
                  objectFit: 'contain',
                }}
              />
            </div>
          </Slide>
        ))}
      </Slider>
    </div>,
    document.body,
  );
};

export default FullScreenModal;
