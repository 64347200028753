import React from 'react';
import type { LineItem } from '@Types/cart/LineItem';
import Image from '../../../../../frontastic/lib/image';
import imageUrlResize from '../../../../../helpers/imageUrlResize';
import Price from '../../../../commercetools-ui/price';
import CartItemAttributes from '../../../cart/cart-form/cart-item/attributes';

export interface Props {
  readonly lineItem: LineItem;
  readonly goToProductPage: (_url: string) => void;
}

const LineItem = ({ lineItem, goToProductPage }: Props) => {
  return (
    <div
      className="flex h-28 w-full cursor-pointer gap-4 border border-neutral-200 bg-white p-4"
      onClick={() => goToProductPage(lineItem._url)}
    >
      <div className="flex w-16 shrink-0 items-center md:w-28">
        <Image
          src={imageUrlResize(lineItem.variant.images[0], 'medium')}
          alt={lineItem.name}
          className="rounded-md bg-white"
        />
      </div>
      <div className="flex grow flex-col justify-center gap-2 md:flex-row md:items-center md:justify-between md:gap-8">
        <div className="w-full">
          <h3 className="mb-2 font-semibold">
            <div>{lineItem.variant.attributes.brand}</div>
            <div>{lineItem.variant.attributes.model_name}</div>
          </h3>
          <div className="flex w-full items-start justify-between">
            <div className="flex">
              <CartItemAttributes
                variant={lineItem.variant}
                attributes={['model_year', 'frame_height_manufacturer', 'mileage_in_km']}
              />
            </div>
            <Price price={lineItem.price} className="rounded-4xl bg-accent-300 px-2 text-sm font-bold" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LineItem;
