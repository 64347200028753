import React, { useEffect, useRef, useState } from 'react';
import NextLink from 'next/link';
import type { Breadcrumb } from '@Types/product/Category';
import classnames from 'classnames';
import ArrowRightIcon from 'components/icons/arrow-right';
import HomeIcon from 'components/icons/home';
import measureElement from '../utils/MeasureElement';
import StructuredData from '../utils/StructuredData';

export type Props = {
  breadcrumbs: Breadcrumb[];
  isPDP?: boolean;
};

type ListItemProps = {
  name: string;
  url: string;
  isLast?: boolean;
};

type OrderedListProps = {
  breadcrumbs: Breadcrumb[];
  isPDP: boolean;
  showEllipsis?: boolean;
};

const ListItem: React.FC<ListItemProps> = ({ name, url, isLast = false }) => {
  const nameSpan = (
    <span
      className={classnames('text-xs', {
        'text-gray-700': !isLast,
        'font-bold': isLast,
      })}
    >
      {name}
    </span>
  );

  return (
    <li className="flex items-center gap-1">
      <ArrowRightIcon className="size-4" />
      {url ? (
        <NextLink href={url} className="flex items-center">
          {nameSpan}
        </NextLink>
      ) : (
        nameSpan
      )}
    </li>
  );
};

const OrderedList: React.FC<OrderedListProps> = ({ breadcrumbs, isPDP, showEllipsis = false }) => (
  <ol className="flex items-center" role="list">
    <li className="mr-1">
      <NextLink href="/" className="text-neutral-700 hover:text-gray-500" aria-hidden>
        <HomeIcon className="size-4" />
        <span className="sr-only">Home</span>
      </NextLink>
    </li>
    {breadcrumbs.map(({ name, url }, index) => {
      if (index < breadcrumbs.length - 1) {
        if (showEllipsis) {
          if (index === breadcrumbs.length - 2) {
            return <ListItem key={name} name="…" url={url} />;
          }

          return null;
        }

        return <ListItem key={name} name={name} url={url} />;
      }

      return <ListItem key={name} name={name} url={isPDP && url} isLast />;
    })}
  </ol>
);

const Breadcrumb: React.FC<Props> = ({ breadcrumbs, isPDP = false }) => {
  const ref = useRef<HTMLElement>();
  const [measuredWidth, setMeasuredWidth] = useState(0);
  const [showEllipsis, setShowEllipsis] = useState(true);

  const items = breadcrumbs.map(({ name, url }, index) => ({
    position: index + 1,
    name,
    item: `${typeof window === 'undefined' ? '' : location.origin}/${url}`,
  }));

  useEffect(() => {
    (async () => {
      const { width } = await measureElement(<OrderedList breadcrumbs={breadcrumbs} isPDP={isPDP} />);

      setMeasuredWidth(width);
    })();
  });

  useEffect(() => {
    setShowEllipsis(measuredWidth > ref.current.clientWidth);
  }, [measuredWidth, ref.current?.clientWidth]);

  return (
    <nav aria-label="Breadcrumb" ref={ref}>
      <StructuredData type="BreadcrumbList" items={items} />
      <OrderedList breadcrumbs={breadcrumbs} isPDP={isPDP} showEllipsis={showEllipsis} />
    </nav>
  );
};

export default Breadcrumb;
