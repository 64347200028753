import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <polygon points="12.4 4.7 11.3 3.6 8 6.9 4.7 3.6 3.6 4.7 6.9 8 3.6 11.3 4.7 12.4 8 9.1 11.3 12.4 12.4 11.3 9.1 8 12.4 4.7" />
  </svg>
);

export default Icon;
