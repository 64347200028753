import React from 'react';
import NextLink from 'next/link';
import { Category } from '@Types/navigation/Category';
import classnames from 'classnames';
import Typography from 'components/commercetools-ui/typography';

export interface CategoryMenuProps {
  category: Category;
  categoryIndex?: number | string;
  menuLevel?: number;
  mode?: 'mobile' | 'desktop';
  closeMenu?: any;
  classNames?: {
    heading?: string;
    link?: string;
    list?: string;
    item?: string;
  };
}

const CategoryMenu: React.FC<CategoryMenuProps> = ({
  category,
  categoryIndex,
  menuLevel,
  mode,
  closeMenu,
  classNames,
}) => {
  const isMobile = (): boolean => {
    return mode === 'mobile';
  };

  const getHeaderId = (): string => {
    return isMobile() ? `mobile-category-menu-heading-${categoryIndex}` : `category-menu-heading-${categoryIndex}`;
  };

  return (
    <>
      {category.children?.map((child: Category) => (
        <div
          key={child.categoryId}
          className={classnames('flex flex-col', {
            'last:mb-2': mode === 'mobile',
            'gap-2': mode !== 'mobile',
          })}
        >
          <div id={getHeaderId()} className={`${mode === 'mobile' ? 'px-4 py-3' : 'font-semibold'}`}>
            {child.path ? (
              <NextLink href={child.path} className="font-bold hover:text-neutral-800" onClick={closeMenu}>
                <Typography>{child.name}</Typography>
              </NextLink>
            ) : (
              <Typography>{child.name}</Typography>
            )}
          </div>

          {child.children.map((subChild: Category) => (
            <NextLink
              key={subChild.categoryId}
              href={subChild.path}
              className={classnames('block hover:text-neutral-800', {
                'pb-2 pl-8 last:pb-4': mode === 'mobile',
              })}
              onClick={closeMenu}
            >
              <Typography>{subChild.name}</Typography>
            </NextLink>
          ))}
        </div>
      ))}
    </>
  );
};

export default CategoryMenu;
