import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
    <path d="M22.8,11.4l-1.3-3.5c-.2-.7-.7-1.3-1.3-1.7-.6-.4-1.3-.6-2-.6h-2.4c-.1-.4-.4-.8-.7-1.1-.6-.6-1.3-.9-2.1-.9H4c-.8,0-1.6.3-2.1.9-.6.6-.9,1.3-.9,2.1v10.5c0,.5.2,1,.6,1.4.4.4.9.6,1.4.6h.2c.4.9,1.3,1.5,2.3,1.5s1.9-.6,2.3-1.5h6.9c.4.9,1.3,1.5,2.3,1.5s1.9-.6,2.3-1.5h1.2c.7,0,1.3-.3,1.8-.7.5-.5.7-1.1.7-1.8v-4.1c0-.4,0-.7-.2-1ZM19.1,7.8c.3.2.4.4.5.7l1.1,3h-1.3l-.4-.4c-.4-.4-.9-.6-1.4-.6h-1.6v-3h2.2c.3,0,.6,0,.9.3ZM3,6.5c0-.3.1-.5.3-.7.2-.2.4-.3.7-.3h9c.3,0,.5.1.7.3.2.2.3.4.3.7v5.9H3v-5.9ZM5.5,18.5c-.3,0-.5-.2-.5-.5s.2-.5.5-.5.5.2.5.5-.2.5-.5.5ZM17,18.5c-.3,0-.5-.2-.5-.5,0,0,0,0,0,0s0,0,0,0c0-.3.2-.5.5-.5s.5.2.5.5-.2.5-.5.5ZM20.9,16.9c0,0-.2.1-.4.1h-1.2c-.4-.9-1.3-1.5-2.3-1.5s-1.9.6-2.3,1.5h-6.9c-.4-.9-1.3-1.5-2.3-1.5s-1.9.6-2.3,1.5h-.2v-2.6h11.5c.4,0,.8-.2,1.1-.4.3-.3.4-.7.4-1.1v-.4h1.6l.7.7c.2.2.4.3.7.3h2v3c0,.1,0,.3-.1.4Z" />
  </svg>
);

export default Icon;
