import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="18.2" y="15.5" width="2" height="7.2" transform="translate(-7.9 19.2) rotate(-45)" />
    <path d="M11.4,20.8c-1.2,0-2.4-.2-3.6-.7-1.7-.7-3.2-1.9-4.2-3.5-1-1.5-1.6-3.3-1.6-5.2s1-4.9,2.7-6.6c1.8-1.8,4.1-2.7,6.6-2.7s3.7.5,5.2,1.6c1.5,1,2.7,2.5,3.5,4.2.7,1.7.9,3.6.5,5.4-.4,1.8-1.2,3.5-2.6,4.8-1.3,1.3-3,2.2-4.8,2.6-.6.1-1.2.2-1.8.2ZM11.4,4c-2,0-3.8.8-5.2,2.2-1.4,1.4-2.2,3.2-2.2,5.2s.4,2.9,1.2,4.1c.8,1.2,2,2.2,3.3,2.7,1.4.6,2.8.7,4.3.4,1.4-.3,2.7-1,3.8-2s1.7-2.3,2-3.8c.3-1.4.1-2.9-.4-4.3-.6-1.4-1.5-2.5-2.7-3.3-1.2-.8-2.6-1.2-4.1-1.2Z" />
  </svg>
);

export default Icon;
