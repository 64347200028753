import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className={className}>
    <path d="M21,12c0-2.5-1-4.7-2.6-6.3l-.7-.7-1.4,1.4.7.7c1.3,1.3,2,3,2,4.9,0,3.9-3.1,7-7,7s-.1,0-.2,0l1-1-1.4-1.4-3.4,3.4,3.4,3.4,1.4-1.4-1-1c0,0,.1,0,.2,0,5,0,9-4,9-9Z" />
    <path d="M5,12c0-3.9,3.1-7,7-7s.1,0,.2,0l-1,1,1.4,1.4,3.4-3.4-3.4-3.4-1.4,1.4,1,1c0,0-.1,0-.2,0C7,3,3,7,3,12s1,4.6,2.5,6.3l.7.7,1.4-1.4-.7-.7c-1.2-1.3-2-3-2-4.9Z" />
  </svg>
);

export default Icon;
