import React from 'react';
import ReveloUspBar from '../../../components/revelo-ui/usp-bar';

const UspBarTastic = ({ data }) => {
  const uspItems = (data.uspItemList || []).filter((uspItem) => uspItem.name && uspItem.name.trim().length > 0);
  const textSize = data.textSize || 'text-base';

  return (
    uspItems.length > 0 && (
      <div className="fixed-screen-width lg:relative-width">
        <ReveloUspBar
          items={uspItems}
          className={`${textSize} mt-5 flex flex-col lg:grid lg:grid-cols-${uspItems.length} divide-y divide-solid divide-gray-200 border-solid lg:divide-x lg:divide-y-0 lg:py-5`}
        />
      </div>
    )
  );
};

export default UspBarTastic;
