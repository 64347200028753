import { CartError as CartErrorType } from '@Types/cart/CartError';
import { CartError } from '../cart/errors/CartError';
import { CartItemError } from '../cart/errors/CartItemError';
import { DiscountError } from '../cart/errors/DiscountError';

export class CartErrorUtils {
  public static getCartErrorList(errors: Array<CartError | CartErrorType>): CartError[] {
    return (
      errors.map((error) => {
        switch (true) {
          case error instanceof CartError:
            return error;
          case (error as CartErrorType).invalidItems !== undefined:
            return new CartItemError(error.message, error.errorCode, error.invalidItems);
          case (error as CartErrorType).invalidDiscounts !== undefined:
            return new DiscountError(error.message, error.invalidDiscounts, error.errorCode);
          default:
            return new CartError(error.message, error.errorCode);
        }
      }) ?? []
    );
  }
}
