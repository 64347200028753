import React from 'react';
import { FullPageWidthWrapper } from 'components/revelo-ui/content/full-width-wrapper';
import TextImageSlider from 'components/revelo-ui/content/text-image-slider';

const TextImageSliderTastic = ({ data }) => {
  const updatedData = data.slides.map((item, index) => ({ ...item, id: index }));
  const sliderData = {
    ...data,
    slides: updatedData,
  };

  if (data?.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <TextImageSlider {...sliderData} />
      </FullPageWidthWrapper>
    );
  }

  return <TextImageSlider {...sliderData} />;
};

export default TextImageSliderTastic;
