import React from 'react';

type Props = {
  className?: string;
  onClick?: () => void;
};

const Icon: React.FC<Props> = ({ className, onClick }: Props) => (
  <svg
    width="24"
    height="24"
    className={className}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
  >
    <path d="M9.2,21.3v-8.8L.8,2.7h22.3l-8.4,9.8v6.1l-5.6,2.8ZM5.2,4.7l6,7v6.4l1.6-.8v-5.6l6-7H5.2Z" />
  </svg>
);

export default Icon;
