import React from 'react';
import { useFormat } from 'helpers/hooks/useFormat';

interface Props {
  ctaAction?: () => void;
  classNames?: string;
}

const CartFrozen = ({ classNames, ctaAction }: Props) => {
  //i18n messages
  const { formatMessage } = useFormat({ name: 'cart' });

  return (
    <div className={classNames ?? 'text-red-800'}>
      {formatMessage({ id: 'cart.frozen', defaultMessage: 'Your cart is currently frozen' })}
      {ctaAction && (
        <>
          <p className="cursor-pointer text-sm font-medium text-accent-400 hover:text-accent-500" onClick={ctaAction}>
            {formatMessage({ id: 'cart.frozen.cta', defaultMessage: 'Unfreeze cart now' })}
          </p>
        </>
      )}
    </div>
  );
};

export default CartFrozen;
