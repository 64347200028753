import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.75 12C2.75 6.61522 7.11522 2.25 12.5 2.25C17.8848 2.25 22.25 6.61522 22.25 12C22.25 17.3848 17.8848 21.75 12.5 21.75C7.11522 21.75 2.75 17.3848 2.75 12ZM14.1277 8.08328C13.2389 7.30557 11.7616 7.30557 10.8728 8.08328C10.5611 8.35604 10.0872 8.32445 9.81447 8.01272C9.54171 7.701 9.5733 7.22717 9.88503 6.95441C11.3394 5.68186 13.6611 5.68186 15.1154 6.95441C16.6285 8.27835 16.6285 10.4717 15.1154 11.7956C14.8588 12.0202 14.5761 12.2041 14.278 12.3484C13.6018 12.6756 13.2502 13.1222 13.2502 13.5V14.25C13.2502 14.6642 12.9144 15 12.5002 15C12.086 15 11.7502 14.6642 11.7502 14.25V13.5C11.7502 12.221 12.8095 11.3926 13.6246 10.9982C13.8073 10.9098 13.9765 10.799 14.1277 10.6667C14.9577 9.9404 14.9577 8.80959 14.1277 8.08328ZM12.5 18C12.9142 18 13.25 17.6642 13.25 17.25C13.25 16.8358 12.9142 16.5 12.5 16.5C12.0858 16.5 11.75 16.8358 11.75 17.25C11.75 17.6642 12.0858 18 12.5 18Z"
      fill="#161038"
    />
  </svg>
);

export default Icon;
