import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
    <polygon points="6.3 12.4 5.3 11.3 8.6 8 5.3 4.7 6.3 3.6 10.7 8 6.3 12.4" />
  </svg>
);

export default Icon;
