import React from 'react';
import classNames from 'classnames';
import BellIcon from 'components/icons/bell';
import { Reference } from 'helpers/reference';
import { ImageIntrinsicSize, MediaItemWithMods } from 'frontastic/lib/image';
import Image from '../../../../frontastic/lib/image/nextImage';
import Button from '../button';
import Markdown from '../markdown';

interface HeroBannerProps {
  image: MediaItemWithMods;
  intrinsicSize?: ImageIntrinsicSize;
  imagePosition: 'positionLeft' | 'positionRight';
  containerWidth: number;
  paddingContent: 'large' | 'small';
  textPosition: 'start' | 'center' | 'space-between' | 'end';
  containerBackgroundColor?: string;
  textColor?: string;
  headline?: string;
  headlineFontSize?: 'headline1mega' | 'headline1' | 'headline2' | 'headline3';
  subline?: string;
  enableBtn: boolean;
  link?: Reference;
  buttonType: 'primary' | 'accent' | 'secondary' | 'hollow';
  buttonIcon?: boolean;
  buttonLabel?: string;
}

const HeroBanner: React.FC<HeroBannerProps> = ({
  image,
  intrinsicSize,
  imagePosition,
  containerWidth = 50,
  paddingContent,
  textPosition,
  containerBackgroundColor,
  textColor,
  headline,
  headlineFontSize,
  subline,
  enableBtn,
  link,
  buttonType,
  buttonIcon,
  buttonLabel,
}) => {
  const imageWidth = 100 - containerWidth;

  return (
    <div
      className={classNames('flex flex-wrap', {
        'flex-row-reverse': imagePosition === 'positionRight',
      })}
    >
      <div className={`w-full bg-black-100/20 lg:w-[${imageWidth > 0 ? imageWidth : '100'}%]`}>
        <Image
          src={image.media.file}
          alt={image.media.name}
          className="size-full object-cover"
          priority
          loading="eager"
        />
      </div>
      <div
        style={{
          backgroundColor: containerBackgroundColor || 'unset',
          color: textColor || 'unset',
          justifyContent: textPosition || 'unset',
        }}
        className={classNames(`flex w-full flex-col gap-6 lg:w-[${containerWidth > 0 ? containerWidth : '100'}%]`, {
          'p-6 md:p-10': paddingContent === 'large',
          'p-4 md:p-6': paddingContent === 'small',
        })}
      >
        <Markdown
          text={headline}
          className={classNames('hyphens-manual font-headline', {
            'text-7xl md:text-8xl': headlineFontSize === 'headline1mega',
            'text-5xl md:text-6xl': headlineFontSize === 'headline1',
            'text-3xl md:text-4xl': headlineFontSize === 'headline2',
            'text-2xl': headlineFontSize === 'headline3',
          })}
        />
        <Markdown text={subline} className="hyphens-manual text-md" />
        {enableBtn && (
          <div>
            <Button style={buttonType} reference={link}>
              <span className="flex items-center justify-center gap-2">
                {buttonIcon && <BellIcon className="size-6" />}
                {buttonLabel}
              </span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default HeroBanner;
