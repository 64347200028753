import React from 'react';
import { FullPageWidthWrapper } from 'components/revelo-ui/content/full-width-wrapper';
import HeroBanner from 'components/revelo-ui/content/hero-banner';

const HeroBannerTastic = ({ data }) => {
  if (data?.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <div>
          <HeroBanner {...data} />
        </div>
      </FullPageWidthWrapper>
    );
  }

  return <HeroBanner {...data} />;
};

export default HeroBannerTastic;
