/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { AvailableSize } from '@Types/product/AvailableSize';
import { OpticalConditionModal } from '@Types/product/OpticalConditionModal';
import { Variant } from '@Types/product/Variant';
import classnames from 'classnames';
import ReactMarkdown from 'react-markdown';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ArrowDownIcon from 'components/icons/arrow-down';
import InformationCircleIcon from 'components/icons/informaction-circle';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import Overlay from '../../../content/overlay';
import { Tooltip as TooltipDetails } from '../../../types/Tooltip';
import { TooltipHandler } from '../../../utils/TooltipHandler';
import { VariantSortUtil } from '../../../utils/VariantSortUtil';
import { getTranslationMap } from '../attribute/translationMapping';

export interface Props {
  readonly variant: Variant;
  readonly availableSizes: AvailableSize[];
  readonly containerClassNames?: string;
  onSelectVariant: (sku: string) => void;
  readonly tooltips?: TooltipHandler | TooltipDetails[];
  readonly opticalConditionModal?: OpticalConditionModal;
}

type AttributeDetails = {
  attributeId: string;
  label: string;
  value: any;
};

const MainAttributes = ({
  variant,
  containerClassNames,
  availableSizes,
  onSelectVariant,
  tooltips = [],
  opticalConditionModal,
}: Props) => {
  const { formatMessage } = useFormat({ name: 'product' });

  if (Array.isArray(tooltips)) {
    tooltips = new TooltipHandler(tooltips);
  }

  function getAttributeDetails(attributeId: string): AttributeDetails {
    let value = variant.attributes?.[attributeId];
    if (!Array.isArray(value) || value.length === 0) {
      value = null;
    }

    const translationMap = getTranslationMap(attributeId, value);

    return {
      attributeId,
      label: formatMessage({ id: translationMap.translationId, defaultMessage: attributeId }),
      value: translationMap.displayValue && value !== null ? translationMap.displayValue(value) : value,
    };
  }

  const selectedSize = availableSizes.find((availableSize) => availableSize.size === VariantSortUtil.getSize(variant));

  const attributeInformation = (attributeId) => {
    const attributeDetails = getAttributeDetails(attributeId);

    return (
      <>
        <div className="text-sm text-neutral-600">{attributeDetails.label}</div>
        <div className="text-sm font-bold text-neutral-950">{attributeDetails.value}</div>
      </>
    );
  };

  const triggerIcon: React.JSX.Element = <InformationCircleIcon className="size-5 text-primary-400" />;

  const getOverlayContent = (modal: OpticalConditionModal): React.JSX.Element => {
    const defaultIndex = modal.tabs.findIndex(({ opticalConditionTabTitle }) =>
      getAttributeDetails('condition_optical').value.includes(opticalConditionTabTitle),
    );
    return (
      <Tabs defaultIndex={defaultIndex}>
        <div className="prose-xl my-4 block">
          {opticalConditionModal.headline}
          <br />
          <strong>{getAttributeDetails('condition_optical').value}</strong>
        </div>
        <TabList className="no-scrollbar flex max-w-full list-none gap-3 overflow-x-auto overflow-y-hidden p-0">
          {modal.tabs.map((content, i) => {
            return (
              <Tab
                key={`opticalConditionTabTitle-${i}`}
                className="m-0 cursor-pointer whitespace-nowrap p-2"
                selectedClassName="text-primary-300 border-b-2 border-primary-300 font-bold"
              >
                {content.opticalConditionTabTitle}
              </Tab>
            );
          })}
        </TabList>
        <div className="-mt-[2px] mb-2 h-[2px] w-full bg-neutral-300" />

        <div className="relative h-[200px] w-[650px] max-w-full">
          {modal.tabs.map((content, i) => {
            return (
              <TabPanel key={`tooltipTabContent-${i}`}>
                <ReactMarkdown className="markdown text-left">{content.opticalConditionTabContent}</ReactMarkdown>
              </TabPanel>
            );
          })}
        </div>
      </Tabs>
    );
  };

  return (
    <>
      <div className={containerClassNames ?? 'my-4 grid grid-cols-2 gap-x-10 gap-y-4'}>
        <div>
          {availableSizes.length > 1 ? (
            <div>
              <div className="text-sm text-neutral-600">
                {formatMessage({ id: 'frameSize', defaultMessage: 'Rahmengröße' })}
              </div>
              <RadioGroup
                className="flex flex-wrap gap-x-2 gap-y-1"
                value={selectedSize}
                onChange={(e) => onSelectVariant(e.sku)}
              >
                {availableSizes.map((availableSize) => (
                  <RadioGroup.Option key={availableSize.sku} value={availableSize}>
                    {({ active, checked }) => (
                      <RadioGroup.Label
                        className={classnames(
                          'flex rounded border px-4 py-1 text-sm hover:cursor-pointer hover:border-neutral-950',
                          active || checked || availableSize.size === selectedSize.size
                            ? 'border-neutral-950 bg-neutral-950 text-white'
                            : 'border-neutral-500 bg-white ',
                        )}
                      >
                        {availableSize.size}
                      </RadioGroup.Label>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
              {selectedSize.count > 1 && (
                <div
                  className="mt-4 cursor-pointer text-xs text-secondary-600 underline"
                  onClick={() => {
                    const element = document.getElementById('variants-heading');
                    element?.scrollIntoView({
                      behavior: 'smooth',
                    });
                  }}
                >
                  {selectedSize.count}
                  {formatMessage({ id: 'variantsForThisSize', defaultMessage: ' Varianten für diese Größe' })}
                </div>
              )}
            </div>
          ) : (
            <div>{attributeInformation('frame_height_manufacturer')}</div>
          )}
        </div>
        <div>{attributeInformation('frame_body_height_recommended_in_cm')}</div>
        {variant.attributes['condition_optical']?.[0] && (
          <div>
            <div className="text-sm text-neutral-600">{getAttributeDetails('condition_optical').label}</div>
            <div className="flex text-sm font-bold">
              {getAttributeDetails('condition_optical').value}
              {opticalConditionModal.enable && opticalConditionModal.tabs.length > 0 && (
                <Overlay
                  idSelector="optical-condition-modal"
                  wrapperClassNames="flex ml-1.5"
                  text={getOverlayContent(opticalConditionModal)}
                  triggerType="buttonClick"
                  triggerText={triggerIcon}
                  maxHeight="80%"
                />
              )}
            </div>
          </div>
        )}
        {variant.attributes['is_ebike']?.[0] === 'TRUE' && (
          <div>
            {variant.attributes['mileage_in_km'] ? (
              attributeInformation('mileage_in_km')
            ) : (
              <>
                <div className="text-sm text-neutral-600">
                  {formatMessage({ id: 'mileage', defaultMessage: 'Laufleistung' })}
                </div>
                <div className="flex text-sm font-bold">
                  {formatMessage({ id: 'mileageNotSet', defaultMessage: 'nicht angegeben' })}
                  {tooltips.hasTooltip('mileageNotSet') && (
                    <Overlay
                      idSelector="mileageNotSet"
                      wrapperClassNames="flex ml-2"
                      text={tooltips.getTooltip('mileageNotSet').content}
                      triggerType="buttonClick"
                      triggerText={triggerIcon}
                      maxHeight="80%"
                    />
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <button
        className="mb-3 flex gap-2 lg:hidden"
        onClick={() => {
          const element = document.getElementById('details-heading');
          element?.scrollIntoView({
            behavior: 'smooth',
          });
        }}
      >
        <div className="text-sm font-bold text-secondary-600 underline">
          {formatMessage({ id: 'allBikeAttributes', defaultMessage: 'Alle Bike Eigenschaften' })}
        </div>
        <ArrowDownIcon className="size-6 text-secondary-600" />
      </button>
    </>
  );
};

export default MainAttributes;
