/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from 'react';
import { getTranslationMap } from './translationMapping';
import { useFormat } from '../../../../../helpers/hooks/useFormat';

export interface Props {
  readonly attributeId?: string;
  readonly value?: any;
}

export const Attribute = ({ attributeId, value }: Props) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const translationMap = getTranslationMap(attributeId, value);

  return (
    <>
      <span className="break-words">
        {formatMessage({
          id: translationMap.translationId,
          defaultMessage: translationMap.defaultMessage || attributeId,
        })}
      </span>
      <span className="break-words">{translationMap.displayValue ? translationMap.displayValue(value) : value}</span>
    </>
  );
};

export default Attribute;
