import React, { useEffect, useRef, useState } from 'react';
import Image, { ImageIntrinsicSize, MediaItemWithMods } from 'frontastic/lib/image';
import { Reference, ReferenceLink } from 'helpers/reference';
import useMatchMediaQuery from 'helpers/hooks/useMatchMediaQuery';

interface ImageScrollerProps {
  images: Array<{
    media: MediaItemWithMods;
    imageIntrinsicSize?: ImageIntrinsicSize;
    name?: string;
    enableLink?: boolean;
    reference?: Reference;
  }>;
}

// Todo: fix image
const ImageScroller: React.FC<ImageScrollerProps> = ({ images }) => {
  const containerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const [atStart, setAtStart] = useState(false);
  const [atEnd, setAtEnd] = useState(false);
  const isMobileSize = useMatchMediaQuery('(max-width: 767px)');

  const handleScroll = () => {
    if (containerRef.current) {
      const container = containerRef.current;
      const containerWidth = container.offsetWidth;
      const contentWidth = container.scrollWidth;

      setAtStart(container.scrollLeft === 0);
      setAtEnd(container.scrollLeft + containerWidth === contentWidth);
    }
  };

  console.log('isMobileSize', isMobileSize);
  useEffect(() => {
    if (containerRef.current) {
      const container = containerRef.current;
      if (isMobileSize) {
        container.scrollLeft = (container.scrollWidth - container.offsetWidth) / 2;
      }

      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!containerRef.current) {
      return;
    }

    setIsDragging(true);
    setStartX(e.pageX - containerRef.current.offsetLeft);
    setScrollLeft(containerRef.current.scrollLeft);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging || !containerRef.current) {
      return;
    }

    e.preventDefault();
    const x = e.pageX - containerRef.current.offsetLeft;
    const walk = (x - startX) * 2;
    containerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const gradientClass = `absolute top-0 h-full w-1/6 pointer-events-none transition-opacity duration-300 from-white to-transparent lg:hidden`;

  return (
    <div className="relative w-full overflow-hidden py-2">
      <div className={`${gradientClass} left-0 bg-gradient-to-r ${atStart ? 'opacity-0' : 'opacity-100'}`} />
      <div className={`${gradientClass} right-0 bg-gradient-to-l ${atEnd ? 'opacity-0' : 'opacity-100'}`} />

      <div
        ref={containerRef}
        className="mx-auto flex max-w-full cursor-grab gap-[22px] overflow-x-auto px-6 active:cursor-grabbing sm:max-w-screen-lg lg:w-full lg:max-w-[1186px] lg:cursor-auto lg:justify-around lg:px-10 xl:px-0"
        style={{
          scrollSnapType: 'x mandatory',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
      >
        {images.map((image, index) => (
          <div key={index}>
            {image.enableLink ? (
              <ReferenceLink target={image.reference} className="">
                <div className="lg:shrink-1 flex h-7 w-[110px] shrink-0 items-center justify-center px-2 md:h-10 md:w-[150px] md:px-5 md:py-2">
                  <img
                    src={image.media.media.file}
                    className="max-h-full max-w-full object-contain"
                    draggable={false}
                  />
                </div>
              </ReferenceLink>
            ) : (
              <div className="lg:shrink-1 flex h-7 w-[110px] shrink-0 items-center justify-center px-2 md:h-10 md:w-[150px] md:px-5 md:py-2">
                <img src={image.media.media.file} className="max-h-full max-w-full object-contain" draggable={false} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageScroller;
