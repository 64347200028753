import React, { useState, FC, Children, CSSProperties, useEffect } from 'react';
import classnames from 'classnames';
import { Navigation, Pagination, Thumbs, Zoom, Autoplay } from 'swiper/modules'; // eslint-disable-line import/no-unresolved
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'; // eslint-disable-line import/no-unresolved
import 'swiper/css'; // eslint-disable-line import/no-unresolved
import 'swiper/css/navigation'; // eslint-disable-line import/no-unresolved
import 'swiper/css/pagination'; // eslint-disable-line import/no-unresolved
import 'swiper/css/scrollbar'; // eslint-disable-line import/no-unresolved
import 'swiper/css/thumbs'; // eslint-disable-line import/no-unresolved
import 'swiper/css/zoom'; // eslint-disable-line import/no-unresolved
import useMediaQuery from '../../../helpers/hooks/useMediaQuery';
import { desktop, mobile, tablet } from '../../../helpers/utils/screensizes';
import { SwiperUtils } from '../utils/SwiperUtils';

export type SliderProps = {
  className?: string;
  spaceBetween?: number;
  slidesPerView?: number;
  arrows?: boolean;
  dots?: boolean;
  fitToSlides?: boolean;
  slideWidth?: number;
  withThumbs?: boolean;
  overlapDots?: boolean;
  thumbChildren?: React.ReactNode;
  navigationSize?: number;
  sliderVersion?: string;
  autoHeight?: boolean;
  autoplay?: boolean;
  delay?: number;
} & SwiperProps;

const Slider: FC<SliderProps> = ({
  className,
  slideWidth,
  slidesPerView,
  fitToSlides = false,
  arrows = false,
  dots = true,
  spaceBetween = 20,
  overlapDots = false,
  withThumbs,
  children,
  thumbChildren,
  navigationSize,
  sliderVersion,
  autoHeight = false,
  autoplay = false,
  delay,
  ...props
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const validToFit: boolean = Boolean(fitToSlides) && Boolean(slideWidth) && Boolean(slidesPerView);
  const sliderWidth: CSSProperties['width'] = validToFit
    ? `${spaceBetween * (slidesPerView! - 1) + slideWidth! * slidesPerView!}px`
    : undefined;

  const [isMobileSize] = useMediaQuery(mobile);
  const [isTabletSize] = useMediaQuery(tablet);
  const [isDesktopSize] = useMediaQuery(desktop);
  const sliderHeight = isDesktopSize ? 425 : isMobileSize ? 320 : 260;
  const thumbSliderHeight = isMobileSize ? 100 : 62;

  const slides = Children.map(children, (child) => {
    if (React.isValidElement(child) && child.type === SwiperSlide) {
      return child;
    }

    return <SwiperSlide>{child}</SwiperSlide>;
  });

  const thumbs = Children.map(thumbChildren ?? children, (child) => (
    <SwiperSlide className="slider__thumb" style={{ height: `${thumbSliderHeight}px`, overflow: 'hidden' }}>
      {child}
    </SwiperSlide>
  ));

  const mainClass = sliderVersion ? `revelo-slider-${sliderVersion}` : 'revelo-slider';

  const containerClassName = classnames('slider__container', {
    'slider__container--fit': validToFit,
    'slider__container--with-thumbs': withThumbs,
  });

  const slidesClassName = classnames(mainClass, className, `${mainClass}-${isTabletSize ? 'outer' : 'inner'}`);

  const thumbsClassName = classnames(
    `${mainClass}__thumbs`,
    `${mainClass}-${isTabletSize ? 'outer' : 'inner'}__thumbs`,
  );

  const autoplayOptions = autoplay
    ? {
        autoplay: {
          delay: delay,
        },
      }
    : {};

  useEffect(() => {
    thumbsSwiper?.on('click', () => {
      const slideToIndex = SwiperUtils.isLastVisibleSlideSelected(thumbsSwiper)
        ? SwiperUtils.getSlideToIndex(thumbsSwiper, 2)
        : SwiperUtils.isFirstVisibleSlideSelected(thumbsSwiper)
        ? SwiperUtils.getSlideToIndex(thumbsSwiper, -2)
        : -1;

      if (slideToIndex >= 0) {
        thumbsSwiper.slideTo(slideToIndex);
      }
    });
  }, [thumbsSwiper]);

  const mainSlider = (
    <Swiper
      className={slidesClassName}
      modules={[Navigation, Pagination, Thumbs, Zoom, Autoplay]}
      thumbs={{ swiper: thumbsSwiper }}
      pagination={dots ? { clickable: true, bulletActiveClass: 'slider__bullet--active' } : false}
      slidesPerView={slidesPerView ?? 'auto'}
      spaceBetween={spaceBetween}
      navigation={arrows}
      style={{
        width: sliderWidth,
        height: autoHeight ? 'auto' : `${sliderHeight + (!dots || overlapDots ? 0 : 40)}px`,
        paddingBottom: !dots || overlapDots ? 0 : 40,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '--swiper-navigation-size': `${navigationSize ?? 30}px`,
      }}
      zoom={true}
      threshold={10}
      {...props}
      {...autoplayOptions}
    >
      {slides}
    </Swiper>
  );

  return (
    <div className={containerClassName}>
      {withThumbs ? (
        <>
          <div className="slider__slides">{mainSlider}</div>
          <div className="slider__thumbs mt-4 lg:mt-2">
            <Swiper
              className={thumbsClassName}
              modules={[Navigation, Thumbs]}
              navigation
              spaceBetween={15}
              slidesPerView={4}
              watchSlidesProgress
              onSwiper={setThumbsSwiper}
              threshold={10}
            >
              {thumbs}
            </Swiper>
          </div>
        </>
      ) : (
        mainSlider
      )}
    </div>
  );
};

export default Slider;
export { SwiperSlide as Slide };
