import React, { useEffect, useRef, useState } from 'react';
import { Disclosure, Transition } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import classnames from 'classnames';
import { Accordion as AccordionDetails } from '../../types/Accordion';
import Button from '../button';

type AccordionProps = {
  accordion: AccordionDetails;
  children?: React.ReactNode;
  classNames?: {
    wrapper?: string;
    headlineWrapper?: string;
    headline?: string;
    contentWrapper?: string;
    buttonWrapper?: string;
    button?: string;
  };
  hasNoBackground?: boolean;
  shouldScrollIntoView?: boolean;
};

const Accordion: React.FC<AccordionProps> = ({
  accordion,
  children,
  classNames,
  hasNoBackground = false,
  shouldScrollIntoView = true,
}) => {
  const accordionRef = useRef<HTMLDivElement>(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { headline, anchor, button, content, showContent } = accordion;

  let scrollToTop = !!accordion.showContent;

  const scrollIntoView = (scrollToTop = false): void => {
    if (accordionRef.current) {
      if (accordionRef.current.getBoundingClientRect().bottom > window.innerHeight) {
        accordionRef.current.scrollIntoView({
          behavior: 'smooth',
          block: scrollToTop ? 'start' : 'end',
          inline: 'nearest',
        });
      }
    }
  };

  const contentPanel = (
    <Disclosure.Panel
      className={classnames(classNames?.contentWrapper ?? 'px-3 py-5 text-base', {
        'bg-neutral-100': !hasNoBackground && isPanelOpen,
      })}
    >
      {content}
      {children && <div>{children}</div>}
      {button && (
        <div className={classNames?.buttonWrapper ?? 'mt-7 text-left'}>
          <Button
            style={button.type}
            reference={button.reference}
            className={classNames?.button ?? 'px-4'}
            disabled={button.disabled}
          >
            {button.label}
          </Button>
        </div>
      )}
    </Disclosure.Panel>
  );

  useEffect(() => {
    if (isPanelOpen && shouldScrollIntoView) {
      scrollIntoView(scrollToTop);
      scrollToTop = false;
    }
  }, [isPanelOpen, shouldScrollIntoView]);

  return (
    <div className={classNames?.wrapper} id={anchor} ref={accordionRef}>
      <Disclosure defaultOpen={showContent}>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={classnames(
                classNames?.headlineWrapper ?? 'group mb-1 flex w-full items-center justify-between p-3',
                { 'bg-neutral-100': !hasNoBackground && !open, 'bg-neutral-300': !hasNoBackground && open },
              )}
            >
              <span
                className={classNames?.headline ?? 'text-headline text-left text-lg font-semibold text-neutral-950'}
              >
                {headline}
              </span>
              <span className="ml-3 flex items-center">
                {open ? (
                  <MinusIcon className="block size-6 text-neutral-950 group-hover:text-gray-500" aria-hidden="true" />
                ) : (
                  <PlusIcon className="block size-6 text-neutral-950" aria-hidden="true" />
                )}
              </span>
              {content && <span className="hidden">{content}</span>}
            </Disclosure.Button>
            <Transition
              className="overflow-hidden"
              enter="transition-all ease-in duration-700"
              enterFrom="transform max-h-0"
              enterTo="transform max-h-screen"
              leave="transition-all ease-out duration-500"
              leaveFrom="transform max-h-screen"
              leaveTo="transform max-h-0"
              after-leave={setIsPanelOpen(open)}
            >
              {contentPanel}
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Accordion;
