import React from 'react';
import Typography from 'components/commercetools-ui/typography';
import ReveloIcon from '../icon';
import { renderItem } from './renderItem';
import { FooterColumn } from '../types/FooterColumn';

export interface ColumnProps {
  column: FooterColumn;
}

const Column: React.FC<ColumnProps> = ({ column }) => (
  <div>
    <div className="flex space-x-2 px-6">
      <h3 className="text-base font-semibold text-neutral-950">
        <Typography>{column.title}</Typography>
      </h3>
    </div>
    <ul role="list" className="mt-2 space-y-3 px-6">
      {column.items.map((item, i) => (
        <li key={i} className="text-base">
          {renderItem(item, 'text-neutral-950')}
        </li>
      ))}
    </ul>
  </div>
);

export default Column;
