import CartItemErrorComponent from './cart-item-error';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { CartItemError } from '../../cart/errors/CartItemError';
import { CartError as BaseError } from '../errors/CartError';

export interface Props {
  readonly error: BaseError;
}

const CartError = ({ error }: Props) => {
  const { formatMessage } = useFormat({ name: 'cart' });

  return (
    <section aria-labelledby="cart-error" className="flex-auto overflow-y-auto px-0 pb-4 pt-0">
      <div className="border-y border-red-500 bg-red-100 px-4 py-3 text-red-700" role="alert">
        <div>
          <span className="font-bold">
            {formatMessage({ id: 'cart.error.title', defaultMessage: 'Error processing cart' })}
          </span>
        </div>
        {error instanceof CartItemError ? (
          <CartItemErrorComponent error={error} />
        ) : (
          <div className="pt-2 text-sm">
            {error.message ? (
              <span>{error.message}</span>
            ) : (
              <span>
                {formatMessage({
                  id: 'cart.error.description',
                  defaultMessage: 'Something went wrong while processing your cart',
                })}
              </span>
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default CartError;
