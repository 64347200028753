import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M2,5v14c0,0,0,.1.1.1h19.7c0,0,.1,0,.1-.1V5c0,0,0-.1-.1-.1H2.1c0,0-.1,0-.1.1ZM19.8,6.3l-7.7,7.4-7.7-7.4h15.4ZM3.4,17.7V7.4l8.6,8.3,8.5-8.2v10.2H3.4h0Z" />
  </svg>
);

export default Icon;
