import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="80" height="80" viewBox="0 0 80 80" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.2,61.5l21.2-21.2s0,0,0-.1c0,0,0,0,0-.1s0,0,0-.1c0,0,0,0,0-.1l-1.8-1.8s0,0-.1,0c0,0,0,0-.1,0s0,0-.1,0c0,0,0,0-.1,0l-17.6,17.6V18.8c0-.2-.1-.3-.3-.3h-2.5c-.2,0-.3.1-.3.3v37l-17.6-17.6s0,0-.1,0c0,0,0,0-.1,0s0,0-.1,0c0,0,0,0-.1,0l-1.8,1.8s0,0,0,.1c0,0,0,0,0,.1s0,0,0,.1c0,0,0,0,0,.1l21.2,21.2c.1.1.3.1.4,0h0Z" />
  </svg>
);

export default Icon;
