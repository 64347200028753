import React, { useEffect } from 'react';
import { RegisterOptions, useFormContext, useWatch } from 'react-hook-form';
import Spinner from 'components/commercetools-ui/spinner';
import Image from '../../../../frontastic/lib/image';
import { getFieldError } from '../../utils/FormFieldUtils';
import ErrorElement from '../form-elements/ErrorElement';
import LabelElement from '../form-elements/LabelElement';

interface Option {
  id?: string;
  value?: string;
  label?: string;
  image?: string;
  default?: boolean;
  description?: string;
}

interface Props {
  readonly onChange?: (val: string) => void;
  readonly name: string;
  readonly formId?: string;
  readonly headline?: string;
  readonly subline?: string;
  readonly label?: string | React.JSX.Element;
  readonly smallLabel?: string | React.JSX.Element;
  readonly options: Option[];
  readonly classNames?: string;
  readonly labelClassNames?: string;
  readonly smallLabelClassNames?: string;
  readonly containerClassNames?: string;
  readonly validation?: RegisterOptions;
  readonly waitingForOptions?: boolean;
}

const FormFieldRadioGroup = ({
  onChange,
  name,
  label,
  smallLabel,
  options,
  labelClassNames,
  smallLabelClassNames,
  containerClassNames,
  formId,
  validation,
  waitingForOptions = false,
}: Props) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();

  const fieldValue = useWatch({
    control,
    name: name,
  });

  useEffect(() => {
    if (onChange) {
      onChange(fieldValue);
    }
  }, [fieldValue, onChange]);

  const fieldError = getFieldError(name, errors);

  const labelElement = (
    <LabelElement
      fieldId={name}
      formId={formId}
      label={label}
      smallLabel={smallLabel}
      classNames={labelClassNames || 'text-base font-medium text-gray-900 dark:text-light-100'}
      smallLabelClassNames={smallLabelClassNames || 'block font-normal text-sm leading-5 text-neutral-700'}
    />
  );

  const errorElement = (
    <ErrorElement
      error={fieldError}
      fieldId={name}
      className="mt-4 block text-sm font-medium text-red-500 dark:text-light-100"
    />
  );

  return (
    <div className={containerClassNames ? containerClassNames : 'col-span-full'}>
      {labelElement}
      <fieldset className="mt-4">
        <legend className="sr-only">{label}</legend>
        {waitingForOptions ? (
          <div className="flex justify-between">
            <Spinner />
          </div>
        ) : (
          <div className="space-y-4">
            {options.map((option, index) => (
              <div key={index} className="flex flex-wrap items-center gap-4">
                <input
                  id={option.id}
                  name={name}
                  type="radio"
                  checked={option.default || false}
                  value={option.value}
                  className={`peer size-5 flex-none cursor-pointer border-2 border-gray-300`}
                  {...register(name, validation)}
                />
                <label
                  htmlFor={option.id}
                  className="block cursor-pointer text-sm font-medium text-gray-700 dark:text-light-100"
                >
                  {option.image ? (
                    <div className="flex items-center space-x-2">
                      <Image
                        src={option.image}
                        alt={option.label}
                        className="h-[25px] flex-none rounded object-cover"
                      />
                      <span>{option.label}</span>
                    </div>
                  ) : (
                    option.label
                  )}
                </label>
                {option.description && (
                  <div className="hidden basis-full pl-9 text-sm font-normal text-neutral-600 peer-checked:block">
                    {option.description}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </fieldset>
      {errorElement}
    </div>
  );
};

export default FormFieldRadioGroup;
