import React, { FC } from 'react';
import type { Order } from '@Types/cart/Order';
import { useFormat } from 'components/../helpers/hooks/useFormat';
import useMediaQuery from 'components/../helpers/hooks/useMediaQuery';
import { tablet } from 'components/../helpers/utils/screensizes';
import Accordion from 'components/commercetools-ui/accordion';
import OrderLineItem from './lineItem';
import OrderSummary from './summary';

export interface Props {
  order: Order;
  attributes?: string[];
}

const Order: FC<Props> = ({ order, attributes }) => {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });
  const { formatMessage: formatOrderMessage } = useFormat({ name: 'cart' });

  const [isTabletSize] = useMediaQuery(tablet);

  return (
    <>
      <OrderSummary order={order} />
      <div className="mt-2 w-full text-gray-500">
        <caption className="sr-only">
          {formatProductMessage({
            id: 'products',
            defaultMessage: 'Products',
          })}
        </caption>
        <Accordion
          closedSectionTitle=""
          openSectionTitle={formatOrderMessage({ id: 'order.summary', defaultMessage: 'Order summary' })}
          iconColor={isTabletSize ? 'text-gray-500' : 'text-accent-400'}
        >
          <div className="flex flex-row gap-4 divide-y divide-gray-200 text-sm">
            {order.lineItems.map((product) => (
              <div key={product.lineItemId} className="w-24 text-center sm:w-40">
                <OrderLineItem lineItem={product} attributes={attributes} />
              </div>
            ))}
          </div>
        </Accordion>
      </div>
    </>
  );
};

export default Order;
