import React, { useEffect, useState } from 'react';
import Image from 'next/image';
import Slider, { Slide, SliderProps } from 'components/revelo-ui/slider';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import imageUrlResize from 'helpers/imageUrlResize';
import { desktop } from 'helpers/utils/screensizes';
import FullScreenModal from '../lightbox';

export interface UIImage {
  id?: string;
  src?: string;
  alt?: string;
}

export interface Props {
  images: UIImage[];
  imageFullScreenEnabled?: boolean;
}

export const ProductSlider = ({ images, imageFullScreenEnabled = false }: Props) => {
  const [isDesktopSize] = useMediaQuery(desktop);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  imageFullScreenEnabled = true;

  useEffect(() => {
    if (isFullScreen) {
      document.body.style.overflow = 'hidden';
    } else document.body.style.overflow = 'unset';
  }, [isFullScreen]);

  const handleImageClick = (index: number) => {
    setActiveImageIndex(index);
    setIsFullScreen(true);
  };

  if (!images) {
    return null;
  }

  const getImage = (image, index: number, isThumb = false) => {
    return (
      <Image
        loader={({ src }) => src}
        fill
        src={imageUrlResize(image.src, isThumb ? 'small' : 'original')}
        alt={image.alt}
        className="w-full cursor-pointer object-center"
        onClick={() => !isThumb && imageFullScreenEnabled && handleImageClick(index)}
        key={`zoom-image--${index}`}
        style={{
          objectFit: 'contain',
        }}
      />
    );
  };

  const sliderConfiguration: SliderProps = {
    slidesPerView: 1,
    thumbChildren: images.map((image, index) => getImage(image, index, true)),
    arrows: true,
    dots: false,
    withThumbs: true,
    fitToSlides: true,
    zoom: {
      maxRatio: 5,
    },
    sliderVersion: 'v2',
  };

  return (
    <>
      {images.length > 0 ? (
        <>
          <Slider {...sliderConfiguration} key={images[0]?.id}>
            {images?.map((image, index) => (
              <Slide zoom={!isDesktopSize} key={`${image.id}${index}`}>
                {getImage(image, index, false)}
              </Slide>
            ))}
          </Slider>
          {isFullScreen && imageFullScreenEnabled && (
            <FullScreenModal images={images} activeIndex={activeImageIndex} onClose={() => setIsFullScreen(false)} />
          )}
        </>
      ) : (
        <div className="mx-4 flex h-[425px] items-center justify-center bg-neutral-300 text-white">NO IMAGES</div>
      )}
    </>
  );
};
