import React from 'react';
import { Product } from '@Types/product/Product';
import Slider, { SliderProps } from 'components/revelo-ui/slider';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { mobile, tablet, desktop } from 'helpers/utils/screensizes';
import Tile from './tile';

export interface Props {
  products: Product[];
  title: string;
  autoplay?: boolean;
  delay?: number;
}

export default function ProductSlider({ products, title, autoplay, delay }: Props) {
  const [isMobileSize] = useMediaQuery(mobile);
  const [isTabletSize] = useMediaQuery(tablet);
  const [isDesktopSize] = useMediaQuery(desktop);

  const sliderFixedMood: SliderProps = {
    slidesPerView: isMobileSize ? (isTabletSize ? (isDesktopSize ? 4 : 3) : 1) : 1,
    arrows: isMobileSize,
    dots: !isMobileSize,
    spaceBetween: 16,
    sliderVersion: 'product',
    autoHeight: true,
    navigationSize: 50,
    autoplay: autoplay,
    delay: delay,
  };

  const sliderConfiguration: SliderProps = sliderFixedMood;

  return (
    <div className="py-4 lg:mx-auto lg:max-w-7xl">
      <div className="w-auto text-center">
        <h2 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 dark:text-light-100">{title}</h2>
      </div>

      <div className="relative mt-8">
        <div className="relative -mb-6 w-full overflow-x-auto pb-6">
          <Slider {...sliderConfiguration}>
            {products.slice(0, 15).map((product, index: number) => (
              <Tile {...product} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}
