import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="currentColor"
    strokeWidth="1.23214"
    className={className}
  >
    <path
      d="M8.82087 4.13512C7.73092 3.15959 6.2916 2.56641 4.71373 2.56641C3.99367 2.56641 3.30247 2.68994 2.66016 2.91696V12.6714C3.30247 12.4444 3.99367 12.3209 4.71373 12.3209C6.2916 12.3209 7.73092 12.9141 8.82087 13.8896M8.82087 4.13512C9.91082 3.15959 11.3501 2.56641 12.928 2.56641C13.6481 2.56641 14.3393 2.68994 14.9816 2.91696V12.6714C14.3393 12.4444 13.6481 12.3209 12.928 12.3209C11.3501 12.3209 9.91082 12.9141 8.82087 13.8896M8.82087 4.13512V13.8896"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Icon;
