import Button from '../../../../components/revelo-ui/content/button';

const ButtonTastic = ({ data }) => {
  const { buttons } = data;

  if (buttons.length === 0) return null;

  if (buttons.length === 1) {
    const [button] = buttons;
    return (
      <Button style={button.type} reference={button.reference} onClick={button.onClick}>
        {button.text}
      </Button>
    );
  }

  return (
    <div className="flex flex-wrap justify-center gap-4">
      {buttons.map(({ type, text, reference, onClick }, index) => (
        <Button style={type} reference={reference} onClick={onClick} key={index}>
          {text}
        </Button>
      ))}
    </div>
  );
};

export default ButtonTastic;
