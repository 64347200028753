import { LineItem, Payment } from '@Types/cart';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { CartItemError } from '../../cart/errors/CartItemError';
import { CheckoutResponseError } from '../errors/CheckoutResponseError';

export interface Props {
  readonly payment?: Payment;
  readonly error?: CheckoutResponseError;
}

const PaymentError = ({ payment, error }: Props) => {
  const { formatMessage } = useFormat({ name: 'checkout' });

  return (
    <section aria-labelledby="payment-error" className="flex-auto overflow-y-auto px-0 pb-4 pt-0">
      <div className="border-y border-red-500 bg-red-100 px-4 py-3 text-red-700" role="alert">
        <div>
          <span className="font-bold">
            {formatMessage({ id: 'paymentProcess.failed.title', defaultMessage: 'Failed to process payment' })}
          </span>
          {payment?.paymentMethod && <span className="px-2 text-sm">({payment.paymentMethod})</span>}
        </div>
        <div className="pt-2 text-sm">
          {formatMessage({
            id: 'paymentProcess.failed.description',
            defaultMessage: 'Failed to process the requested payment, please retry or contact support',
          })}
        </div>
        {error?.message && (
          <div className="pt-2 text-sm">
            <span className="mr-1 font-semibold">
              {formatMessage({ id: 'paymentProcess.failed.message.label', defaultMessage: 'Error message' })}:
            </span>
            <span className="italic">{error.message}</span>
          </div>
        )}
        {error && error instanceof CartItemError && error.items.length > 0 && (
          <div className="pl-4 pt-2 text-sm">
            {error.items.map((item: LineItem) => `${item.name} - ${item.variant?.availableQuantity} available`)}
          </div>
        )}
      </div>
    </section>
  );
};

export default PaymentError;
