import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Disclosure } from '@headlessui/react';
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/solid';
import { useFormat } from 'helpers/hooks/useFormat';
import { URLParam } from 'helpers/utils/updateURLParams';
import { isProduction } from '../../../../helpers/utils/environment';

type SortingDisclosureProps = {
  updateSortingParams: (param: URLParam) => void;
  disabled?: boolean;
};

type sortingParamID = 'asc' | 'desc' | 'newest';

const SortingDisclosure: FC<SortingDisclosureProps> = ({ updateSortingParams, disabled = false }) => {
  const router = useRouter();
  const [currentSortingParam, setCurrentSortingParam] = useState<sortingParamID>('newest');
  const { formatMessage } = useFormat({ name: 'product' });
  const isProdEnv = isProduction();
  const isStageEnv = window.location.host.includes('revelo-bravobike.frontend.site');

  const handleChange = (e: ChangeEvent) => {
    setCurrentSortingParam(e.target.id as sortingParamID);

    updateSortingParams({
      key:
        e.target.id === 'newest'
          ? `sortAttributes[0][variants.attributes.${
              isProdEnv && !isStageEnv ? 'stock_change_stankovic' : 'stockchange'
            }.de-DE]`
          : 'sortAttributes[0][price]',
      value: e.target.id === 'newest' ? 'desc' : e.target.id,
    });
  };

  const options: { label: string; value: string }[] = [
    {
      label: formatMessage({ id: 'priceAsc', defaultMessage: 'Price (ascending)' }),
      value: 'asc',
    },
    {
      label: formatMessage({ id: 'priceDesc', defaultMessage: 'Price (descending)' }),
      value: 'desc',
    },
    {
      label: formatMessage({ id: 'newestProducts', defaultMessage: 'Newest' }),
      value: 'newest',
    },
  ];

  useEffect(() => {
    const defaultPriceSortingParamID = router?.query['sortAttributes[0][price]'] as sortingParamID;
    const defaultStockChangeSortingParamID = router?.query[
      `sortAttributes[0][variants.attributes.${
        isProdEnv && !isStageEnv ? 'stock_change_stankovic' : 'stockchange'
      }.de-DE]`
    ] as sortingParamID;

    setCurrentSortingParam(defaultStockChangeSortingParamID ? 'newest' : defaultPriceSortingParamID);
  }, [router?.query]);

  return (
    <div className="pb-6">
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex w-full items-center justify-between py-1 text-neutral-950 hover:text-neutral-900">
              <span className="font-bold">{formatMessage({ id: 'sortBy', defaultMessage: 'Sort by' })}</span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusSmIcon className="size-5" aria-hidden="true" />
                ) : (
                  <PlusSmIcon className="size-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
            <Disclosure.Panel className="grid gap-2 pt-6">
              {options.map(({ label, value }, index) => (
                <label className="flex cursor-pointer items-center text-neutral-950" key={index}>
                  <input
                    id={value}
                    name="price"
                    type="radio"
                    className="mr-2 size-5 border-2 border-neutral-700"
                    onChange={handleChange}
                    checked={value === currentSortingParam}
                    disabled={disabled}
                  />
                  {label}
                </label>
              ))}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default SortingDisclosure;
