import { FieldError, FieldErrors } from 'react-hook-form';

const mandatoryFields: Record<string, string[]> = {
  'checkout.general': ['firstName', 'lastName', 'emailAddress', 'phone', 'payment'],
  'checkout.address': ['firstName', 'lastName', 'streetName', 'streetNumber', 'postalCode', 'city', 'country'],
  'account.address': ['firstName', 'lastName', 'streetName', 'streetNumber', 'postalCode', 'city', 'country', 'phone'],
};

const fieldLabelMap: Record<string, string> = {
  streetName: 'street.name',
  streetNumber: 'street.number',
  postalCode: 'zipCode',
};

const getFieldNameWithoutAddressType = (fieldName: string): string => {
  return fieldName.indexOf('billing.') === 0
    ? fieldName.slice(8)
    : fieldName.indexOf('shipping.') === 0
    ? fieldName.slice(9)
    : fieldName;
};

export const isFieldMandatory = (fieldName: string, source: string): boolean => {
  const fieldList = mandatoryFields?.[source] || [];
  return fieldList.includes(getFieldNameWithoutAddressType(fieldName));
};

export const getLabelIdForField = (fieldName: string): string => {
  fieldName = getFieldNameWithoutAddressType(fieldName).replace(/-./g, (data) => data[1].toUpperCase());
  return fieldLabelMap?.[fieldName] || fieldName;
};

export const getErrorIdForField = (fieldName: string, error?: FieldError, formId?: string): string => {
  const fieldLabel = getLabelIdForField(fieldName);
  return `${formId || ''}.${fieldLabel}.${error?.type || 'error'}`.replace(/^\.+/, '').toLowerCase();
};

export const getLabelFromFieldName = (fieldName: string): string => {
  const label = fieldName.replace(/[A-Z\.]/g, (char) => ` ${char === '.' ? '' : char.toLowerCase()}`).trim();
  return label.charAt(0).toUpperCase() + label.slice(1);
};

export const getFieldError = (fieldName: string, validationErrors: FieldErrors): FieldError | null => {
  const path = fieldName.split('.');
  const error = path.reduce((errors, key) => errors?.[key], validationErrors);

  return (error as FieldError) ?? null;
};
