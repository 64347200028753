import React from 'react';

type Props = {
  className?: string;
};

const Icon: React.FC<Props> = ({ className }: Props) => (
  <svg className={className} width="20" height="20" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5,22h-2v-7c0-1.7-1.3-3-3-3h-3c-1.7,0-3,1.3-3,3v7h-2v-7c0-2.8,2.2-5,5-5h3c2.8,0,5,2.2,5,5v7Z" />
    <path d="M12,9c-2.2,0-4-1.8-4-4s1.8-4,4-4,4,1.8,4,4-1.8,4-4,4ZM12,3c-1.1,0-2,.9-2,2s.9,2,2,2,2-.9,2-2-.9-2-2-2Z" />
  </svg>
);

export default Icon;
