import React from 'react';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import { getLabelFromFieldName, getLabelIdForField, isFieldMandatory } from '../../utils/FormFieldUtils';

interface Props {
  readonly fieldId: string;
  readonly formId?: string;
  readonly label?: string | React.JSX.Element;
  readonly smallLabel?: string | React.JSX.Element;
  readonly classNames?: string;
  readonly smallLabelClassNames?: string;
  readonly isFieldRequired?: boolean;
}

const LabelElement = ({ fieldId, formId, label, smallLabel, classNames, smallLabelClassNames }: Props) => {
  const { formatMessage } = useFormat({ name: 'common' });
  if (!label) {
    label = formatMessage({ id: getLabelIdForField(fieldId), defaultMessage: getLabelFromFieldName(fieldId) });
  }

  // todo - switch to validation.required check ?!
  if (formId) {
    const suffix = isFieldMandatory(fieldId, formId)
      ? formatMessage({ id: 'formField.mandatory', defaultMessage: '' })
      : formatMessage({ id: 'formField.optional', defaultMessage: '' });

    label = `${label} ${suffix.trim()}`.trim();
  }

  return (
    <label
      htmlFor={fieldId}
      className={classNames || 'block text-base font-medium text-neutral-700 dark:text-light-100'}
    >
      <span>{label}</span>
      {smallLabel && <small className={smallLabelClassNames || 'block'}>{smallLabel}</small>}
    </label>
  );
};

export default LabelElement;
