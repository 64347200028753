import React from 'react';
import NextLink from 'next/link';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import Image from 'frontastic/lib/image';
import imageUrlResize from '../../../../helpers/imageUrlResize';
import Typography from '../../typography';

interface Props {
  products: Product[];
}

const getVariantAttributeList = ({ attributes }: Variant) => {
  const attributeList = [];

  if (!Array.isArray(attributes)) {
    const { model_year, frame_height_manufacturer, mileage_in_km } = attributes;

    if (model_year?.[0]) attributeList.push(model_year?.[0]);
    if (frame_height_manufacturer?.[0]) attributeList.push(frame_height_manufacturer?.[0]);
    if (mileage_in_km?.[0]) attributeList.push(`${mileage_in_km?.[0]} km`);
  }

  return attributeList;
};

const renderVariantsList = (variants: Variant[], formatMessage) => {
  variants = variants.filter((variant) => variant.availableQuantity > 0);
  if (variants.length === 0) {
    return null;
  }

  let content;

  if (variants.length > 2) {
    content = (
      <>
        {variants[0].attributes?.model_year && <div className="text-xs">{variants[0].attributes?.model_year?.[0]}</div>}
        <div className="bottom-0 text-xs">
          {formatMessage({ id: 'variants.multiple', defaultMessage: 'different sizes & mileage available' })}
        </div>
      </>
    );
  } else {
    content = variants.map((variant, index) => {
      const attributeList = getVariantAttributeList(variant);

      if (attributeList.length === 0) return null;

      return (
        <div
          key={index}
          className="text-xs"
          dangerouslySetInnerHTML={{
            __html: attributeList.join('<span class="px-2">&centerdot;</span>'),
          }}
        />
      );
    });
  }

  return <div className="relative h-12">{content}</div>;
};

const getProductImageSrc = (product: Product, mustFitToUrl = true, imageIndex = 0) => {
  const variant = product.variants.find((variant) => {
    if (!mustFitToUrl) {
      return variant.availableQuantity > 0;
    }

    return variant.availableQuantity > 0 && new RegExp(`/p/${variant.sku}`).test(product._url ?? '');
  });

  return !variant && mustFitToUrl
    ? getProductImageSrc(product, false)
    : imageUrlResize(variant?.images?.[imageIndex] ?? product.variants[0].images?.[imageIndex] ?? '', 'medium');
};

const getProductPrice = (product: Product, formatMessage) => {
  const availableVariants = product.variants.filter(
    (variant) => variant.availableQuantity && variant.price !== undefined,
  );
  const useFromPrice = availableVariants.length > 1;
  const priceVariant = useFromPrice
    ? availableVariants.sort((a, b) => a.price.centAmount - b.price.centAmount)[0]
    : availableVariants.length === 1
    ? availableVariants[0]
    : product.variants[0];

  const recommendedPriceFormatted = CurrencyHelpers.formatForCurrency(priceVariant.recommendedPrice);
  const priceFormatted = CurrencyHelpers.formatForCurrency(priceVariant.price);

  return (
    <div className="mt-4 flex items-center gap-2">
      <div className="text-sm text-neutral-950 line-through" suppressHydrationWarning>
        <Typography>
          {formatMessage({
            id: 'price.uvp',
            defaultMessage: 'ehem. UVP {price}',
            values: { price: recommendedPriceFormatted },
          })}
        </Typography>
      </div>
      <div
        className="rounded-4xl bg-accent-300 px-2 py-0.5 text-sm font-semibold text-neutral-950"
        suppressHydrationWarning
      >
        <Typography>
          {useFromPrice
            ? formatMessage({
                id: 'price.from',
                defaultMessage: 'ab {price}',
                values: { price: priceFormatted },
              })
            : priceFormatted}
        </Typography>
      </div>
    </div>
  );
};

const List: React.FC<Props> = ({ products }) => {
  //i18n messages
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  return (
    <div className="mx-auto max-w-2xl lg:max-w-7xl">
      <h2 className="sr-only">{formatProductMessage({ id: 'products', defaultMessage: 'Products' })}</h2>
      <ul className="grid grid-cols-1 gap-4 align-bottom sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 lg:gap-10">
        {products?.map((product) => (
          <li
            key={`product-${product.productId}`}
            className="PLP-product relative flex justify-center self-end transition-colors hover:bg-neutral-50"
          >
            {product.flags?.includes('isNew') && (
              <span className="absolute left-2 top-2 z-10 rounded-4xl bg-neutral-100 px-2 py-0.5 text-sm text-neutral-950">
                {formatProductMessage({ id: 'badgeNew', defaultMessage: 'Neu für dich' })}
              </span>
            )}
            <NextLink href={product._url || ''} className="hover-image-link group w-full">
              <div className="relative w-full">
                <Image
                  className="default-image"
                  src={getProductImageSrc(product, true) || ''}
                  alt={product.name}
                  layout="fill"
                />
                <Image
                  className="hover-image"
                  src={getProductImageSrc(product, true, 4) || ''}
                  alt={product.name}
                  layout="fill"
                />
              </div>
              <div className="p-4">
                <div className="text-base font-bold text-neutral-950">{product.variants[0].attributes?.brand?.[0]}</div>
                <h3
                  className="w-full overflow-hidden truncate text-base font-bold text-neutral-950"
                  title={product.variants[0].attributes?.model_name?.[0]}
                >
                  {product.variants[0].attributes?.model_name?.[0]}
                </h3>
                {renderVariantsList(product.variants, formatProductMessage)}
                {getProductPrice(product, formatProductMessage)}
              </div>
            </NextLink>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default List;
