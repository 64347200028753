import React from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/outline';
import { AttributeGroup } from '@Types/product/AttributeGroup';
import { Variant } from '@Types/product/Variant';
import { useFormat } from '../../../../../helpers/hooks/useFormat';
import Attribute from '../attribute';

export interface Props {
  variant: Variant;
  groups: AttributeGroup[];
}

type DisplayGroup = {
  title: string;
  attributes: {
    attributeId: string;
    attributeValue: any;
  }[];
};

export default function VariantAttributes({ variant, groups }: Props) {
  const { formatMessage: formatProductMessage } = useFormat({ name: 'product' });

  const attributeGroups: DisplayGroup[] = groups
    .map((group: AttributeGroup) => ({
      title: group.title,
      attributes: group.attributes
        .map((attributeId: string) => ({
          attributeId: attributeId,
          attributeValue: variant.attributes?.[attributeId],
        }))
        .filter(({ attributeValue }) => !!attributeValue),
    }))
    .filter((group: DisplayGroup) => group.attributes.length > 0);

  return (
    <Disclosure key={variant.id}>
      {({ open }) => (
        <>
          <h3 className="bg-white">
            <Disclosure.Button className="group relative mb-1 flex w-full items-center justify-between px-3 py-2 text-left">
              <span className="font-medium text-gray-900">
                {formatProductMessage({
                  id: 'variants.properties.title',
                  defaultMessage: 'Properties',
                })}
              </span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusIcon className="block size-6 text-slate-900 group-hover:text-gray-500" aria-hidden="true" />
                ) : (
                  <PlusIcon className="block size-6 text-slate-900 group-hover:text-gray-500" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="-mt-0.5 mb-1 bg-white">
            <div className="prose prose-sm p-3">
              {attributeGroups.map((group: DisplayGroup, index: number) => (
                <div className="mb-3" key={`attribute-groups-${index}`}>
                  <div className="mb-2 bg-light-200 px-2 py-1 font-semibold uppercase">{group.title}</div>
                  {group.attributes.map(({ attributeId, attributeValue }, index: number) => (
                    <div key={index} className="grid w-full grid-cols-2 px-2 py-1">
                      <Attribute attributeId={attributeId} value={attributeValue} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
