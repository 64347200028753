import React from 'react';
import { FullPageWidthWrapper } from 'components/revelo-ui/content/full-width-wrapper';
import ImageScroller from 'components/revelo-ui/content/image-scroller';

const ImageScrollerTastic = ({ data }) => {
  if (data?.isFullWidth) {
    return (
      <FullPageWidthWrapper>
        <ImageScroller images={data.images} />
      </FullPageWidthWrapper>
    );
  }

  return <ImageScroller images={data.images} />;
};

export default ImageScrollerTastic;
