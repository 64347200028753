import React, { FC, FormEvent, useEffect } from 'react';
import { useRouter } from 'next/router';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { useFormat } from 'helpers/hooks/useFormat';
import { URLParam } from 'helpers/utils/updateURLParams';
import PriceFilterDisclosure from './price-filter-disclosure';
import SortingDisclosure from './sorting-disclosure';
import TermFilterList from './term-filter-list';
import { useFilter } from '../../../frontastic';
import { isProduction } from '../../../helpers/utils/environment';
import Button from '../content/button';
import { FilterUtils } from '../utils/FilterUtils';

type FiltersProps = {
  facets: Facet[];
  products: Product[];
  totalProducts?: number;
  debounceDelay?: number;
  submitOnSort?: boolean;
  visibleFilters?: string[];
  filterUrl?: string;
  closeFilters?: () => void;
};

const Filters: FC<FiltersProps> = ({
  facets,
  products,
  totalProducts,
  debounceDelay,
  submitOnSort = true,
  visibleFilters = [],
  filterUrl,
  closeFilters,
}) => {
  const { formatMessage } = useFormat({ name: 'product' });
  const { setDebounceDelay, setFilter, submitFilter, isUpdating, setDefaultSorting } = useFilter();
  const isProdEnv = isProduction();
  const isStageEnv = window.location.host.includes('revelo-bravobike.frontend.site');
  const router = useRouter();

  const setTermFilteringParams = (termFilteringParams: URLParam[], shouldSubmit = false, source?: string) => {
    setFilter((filter) => {
      return {
        ...filter,
        termFilteringParams,
        shouldSubmit,
        filterUrl,
      };
    });
  };

  const setPriceFilteringParams = (priceFilteringParams: URLParam[], shouldSubmit = false, source?: string) => {
    setFilter((filter) => ({
      ...filter,
      priceFilteringParams,
      shouldSubmit,
      filterUrl,
    }));
  };

  const setSortingParam = (sortingParam: URLParam) => {
    setFilter((filter) => ({
      ...filter,
      sortingParam,
      shouldSubmit: submitOnSort,
      filterUrl,
    }));
  };

  useEffect(() => {
    setFilter((filter) => {
      const termFacets = FilterUtils.getTermFacets(facets);

      return {
        ...filter,
        shouldSubmit: false,
        terms: FilterUtils.getFilterTerms(termFacets),
        termFilteringParams: FilterUtils.getTermFilteringParams(termFacets),
        priceFilteringParams: FilterUtils.getRangeFilteringParams(facets),
        filterUrl,
      };
    });
  }, [facets]);

  useEffect(() => {
    if (debounceDelay !== undefined) {
      setDebounceDelay(Math.max(0, debounceDelay));
    }

    if (!router.asPath.includes('sortAttributes')) {
      setDefaultSorting({
        key: `sortAttributes[0][variants.attributes.${
          isProdEnv && !isStageEnv ? 'stock_change_stankovic' : 'stockchange'
        }.de-DE]`,
        value: 'desc',
      });
    }
  }, []);

  const updatePriceFilteringParams = (params: URLParam[], shouldSubmit: boolean, source?: string) => {
    setPriceFilteringParams(params, shouldSubmit, source);
  };

  const updateTermFilteringParams = (params: URLParam[], shouldSubmit: boolean, source?: string) => {
    setTermFilteringParams(params, shouldSubmit, source);
  };

  const updateSortingParams = (param: URLParam) => {
    setSortingParam(param);
  };

  const handleFiltersSubmit = (event?: FormEvent) => {
    event?.preventDefault?.();

    submitFilter();

    closeFilters?.();
  };

  return (
    <form onSubmit={handleFiltersSubmit} className="-mx-1 mb-5 bg-white px-1">
      <SortingDisclosure updateSortingParams={updateSortingParams} disabled={isUpdating} />
      <PriceFilterDisclosure
        products={products}
        facets={facets}
        disabled={isUpdating}
        updatePriceFilteringParams={updatePriceFilteringParams}
      />
      <TermFilterList
        facets={facets}
        disabled={isUpdating}
        visibleFilters={visibleFilters.map((filter) => filter.toLowerCase())}
        updateTermFilteringParams={updateTermFilteringParams}
      />
      <div className="sticky bottom-0 mt-6 bg-white py-2 lg:hidden">
        <Button type="submit" style="secondary" fullWidth>
          {formatMessage({
            id: 'applyFilters',
            defaultMessage: 'Apply filters',
            values: {
              totalItems: totalProducts ?? '',
            },
          })}
        </Button>
      </div>
    </form>
  );
};

export default Filters;
