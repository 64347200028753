import React from 'react';
import { XIcon } from '@heroicons/react/outline';
import { Discount } from '@Types/cart/Discount';
import { Money } from '@Types/product/Money';
import { useFormat } from '../../../../../../helpers/hooks/useFormat';
import Price from '../../../../../commercetools-ui/price';
import DiscountSummaryItemProps from '../../interfaces/DiscountSummaryItemProps';

const TextItem = ({ discount, classNames, handleRemove }: DiscountSummaryItemProps) => {
  const { formatMessage } = useFormat({ name: 'cart' });

  const getDiscountPrice = (discount: Discount): Money => ({
    fractionDigits: discount.discountedAmount?.fractionDigits ?? 2,
    currencyCode: discount.discountedAmount?.currencyCode ?? 'EUR',
    centAmount:
      (discount.discountedAmount?.centAmount ?? 0) > 0
        ? -discount.discountedAmount?.centAmount
        : discount.discountedAmount?.centAmount,
  });

  return (
    <div className={classNames?.wrapper}>
      <div className="flex justify-between">
        <dt>
          <label className={classNames?.label || `px-0 text-sm font-medium text-gray-500`}>
            <span className="pr-1 font-semibold">
              {formatMessage({ id: 'cart.discount.label', defaultMessage: 'Discount' })}:
            </span>
            {discount.name ?? discount.code}
          </label>
        </dt>
        <dd>
          <Price
            price={getDiscountPrice(discount)}
            className={classNames?.price || `text-sm font-medium text-gray-900 dark:text-light-100`}
          />
        </dd>
      </div>
      <div className="flex justify-between">
        <dt className="text-xs">{discount.code}</dt>
        <dd>
          {handleRemove && (
            <button type="button" onClick={() => handleRemove(discount)} className="">
              <XIcon className={classNames?.icon || `size-3`} />
            </button>
          )}
        </dd>
      </div>
    </div>
  );
};

export default TextItem;
