import React from 'react';
import { Banner as BannerType } from '@Types/content/Banner';
import { LocaleStorage } from '../../../../frontastic';
import Image from '../../../../frontastic/lib/image';
import { TagManager } from '../../../../frontastic/lib/tag-manager';
import { ReferenceLink, getReferenceTarget } from '../../../../helpers/reference';
import DataValidator from '../../../../helpers/utils/dataValidator';
import { mapLanguage } from '../../../../project.config';
import Button from '../button';
import { landbot } from '../livechat';
import Markdown from '../markdown';

const Banner: React.FC<BannerType> = ({
  backgroundImage,
  backgroundImageFit,
  backgroundColor,
  bannerText,
  textColor,
  buttonLabel,
  buttonStyle,
  buttonLink,
  bannerLink,
  customClassName,
  onClickOpenBikeAlarm,
  onClickOpenLivechat,
  setTriggerBikeAlarmFromBanner,
}) => {
  const locale = mapLanguage(LocaleStorage?.locale ?? 'de');
  const bgImgAlt = backgroundImage?.title !== undefined ? backgroundImage?.title[locale] : 'banner background image';
  backgroundImageFit = backgroundImageFit || 'cover';
  customClassName = customClassName || '';
  const txtColor = textColor || '#161038';
  const validBannerLink = DataValidator.isValidReference(bannerLink) ? bannerLink : undefined;
  const validButtonLink = DataValidator.isValidReference(buttonLink) ? buttonLink : undefined;

  const handleTrackingClickEvent = (link: string) => {
    new TagManager().customEvent('plp-banner-tile-click', { clickTarget: link }).executePush();
  };

  const handleOpenBikeAlarm = (event) => {
    if (event.target.classList?.contains('banner__button') || event.target.tagName.toLowerCase() === 'a') return;

    setTriggerBikeAlarmFromBanner(true);
    handleTrackingClickEvent('bike-alarm');
  };

  const handleOpenLivechat = () => {
    landbot?.current.open();

    handleTrackingClickEvent('livechat');
  };

  return (
    <div
      className={`banner relative flex w-full flex-col justify-center p-4 text-center ${customClassName} ${
        onClickOpenBikeAlarm || onClickOpenLivechat ? 'cursor-pointer' : ''
      }`}
      style={{ backgroundColor: backgroundColor }}
      onClick={
        onClickOpenBikeAlarm
          ? (event) => handleOpenBikeAlarm(event)
          : onClickOpenLivechat
          ? handleOpenLivechat
          : undefined
      }
    >
      {validBannerLink && !onClickOpenBikeAlarm && (
        <ReferenceLink
          target={validBannerLink}
          className="absolute inset-0 z-[2]"
          onClick={() => handleTrackingClickEvent(getReferenceTarget(validBannerLink))}
        />
      )}

      {backgroundImage?.media && (
        <div className="banner__bg-img-wrapper absolute inset-0 z-0">
          <Image
            media={backgroundImage?.media}
            alt={bgImgAlt}
            layout="fill"
            objectFit={backgroundImageFit}
            className="banner__bg-img"
          />
        </div>
      )}

      {bannerText && (
        <div className="banner__text relative z-[1]" style={{ color: txtColor }}>
          <Markdown text={bannerText} />
        </div>
      )}

      {buttonLabel && validButtonLink && (
        <span className={'mt-7.5'}>
          <Button
            style={buttonStyle}
            reference={validButtonLink}
            className={'banner__button relative z-[3] !min-w-0'}
            onClick={() => handleTrackingClickEvent(getReferenceTarget(validButtonLink))}
          >
            {buttonLabel}
          </Button>
        </span>
      )}
    </div>
  );
};

export default Banner;
