import React, { useEffect, useRef, useState } from 'react';
import { CartError } from '@Types/cart/CartError';
import ReveloCheckout from 'components/revelo-ui/checkout';
import Spinner from '../../../components/commercetools-ui/spinner';
import { OrderErrorProps } from '../../../components/revelo-ui/order-error';
import { useFormat } from '../../../helpers/hooks/useFormat';
import { useCart } from '../../provider';

const CheckoutTastic = ({ data }) => {
  const { formatMessage } = useFormat({ name: 'common' });
  const { data: cart, refreshCart, validateCart } = useCart();
  const [cartErrors, setCartErrors] = useState<CartError[]>([]);
  const [isValidating, setIsValidating] = useState<boolean>(true);
  const cartValidated = useRef<boolean>(false);

  const orderErrorData: OrderErrorProps = {
    title: data.orderErrorTitle ?? undefined,
    text: data.orderErrorText ?? undefined,
    image: data.orderErrorImage ?? undefined,
  };

  const getShippingCountries = (data) => {
    return Object.keys(data)
      .filter((key) => key.length === 2 && data[key] === true)
      .map((key) => ({
        data: key,
        display: formatMessage({ id: `country.${key}`, defaultMessage: key }),
      }));
  };

  useEffect(() => {
    if (cart?.cartId !== undefined && !cartValidated.current) {
      (async () => {
        await validateCart().then(async (response) => {
          cartValidated.current = true;
          if (!response.isValid) {
            await refreshCart();
          }
          setCartErrors(response.errors ?? []);
          setIsValidating(false);
        });
      })();
    }
  }, [cart]);

  return (
    <>
      {isValidating ? (
        <div className="flex items-stretch justify-center px-12 py-10">
          <Spinner />
        </div>
      ) : (
        <ReveloCheckout
          billingCountryOptions={getShippingCountries(data)}
          shippingCountryOptions={getShippingCountries(data)}
          orderErrorData={orderErrorData}
          loginLink={data.loginLink}
          paymentDescriptionList={data.paymentDescriptionList}
          paymentPreselection={data.paymentPreselection}
          deliveryTime={data.deliveryTimeSnippet}
          paymentB2BDisclaimer={data.paymentB2BDisclaimer}
          cartErrors={cartErrors}
        />
      )}
    </>
  );
};

export default CheckoutTastic;
