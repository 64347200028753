import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ShippingMethod } from '@Types/cart/ShippingMethod';
import { ShippingMethodMapping } from '@Types/cart/ShippingMethodMapping';
import { ShippingRate } from '@Types/cart/ShippingRate';
import { AttributeGroup } from '@Types/product/AttributeGroup';
import { Product } from '@Types/product/Product';
import { Variant } from '@Types/product/Variant';
import ProductDetailsV1 from 'components/commercetools-ui/products/product-details';
import { ShippingHandler } from 'components/revelo-ui/checkout/utils/ShippingHandler';
import ProductDetails, { UIColor, UIProduct, UISize } from 'components/revelo-ui/products/product-details';
import Offcanvas from 'components/revelo-ui/products/product-details/offcanvas';
import ProductStructuredData from 'components/revelo-ui/utils/ProductStructuredData';
import { useCart, useWishlist } from 'frontastic';
import { addToWishlist } from 'frontastic/actions/wishlist';
import ListCheckIcon from '../../../../components/icons/list-check';
import BenefitsList from '../../../../components/revelo-ui/products/benefits-list';
import LineItem from '../../../../components/revelo-ui/products/product-details/offcanvas/line-item';
import { PageUtils } from '../../../../components/revelo-ui/utils/PageUtils';
import { useFormat } from '../../../../helpers/hooks/useFormat';
import useI18n from '../../../../helpers/hooks/useI18n';
import { TagManager } from '../../../lib/tag-manager';

function ProductDetailsTastic({ data, pageFolder }) {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [shippingMethods, setShippingMethods] = useState<ShippingMethod[]>();
  const [shippingMethodMapping, setShippingMethodMapping] = useState<ShippingMethodMapping>();
  const [shippingRate, setShippingRate] = useState<ShippingRate>(null);
  const [shippingMethodId, setShippingMethodId] = useState<string>();
  const { t: translate } = useI18n();

  const router = useRouter();
  const { product }: { product: Product } = data?.data?.dataSource;
  const { formatMessage } = useFormat({ name: 'cart' });

  const benefits = {
    benefit1: data?.benefit1,
    benefit2: data?.benefit2,
    benefit3: data?.benefit3,
  };

  const pageVersion = PageUtils.getPageVersion(data, 'default');

  const attributeGroups: AttributeGroup[] =
    data.attributeGroups?.map((group) => ({
      title: group.title,
      attributes: group.attributeList.split(';').filter((attribute: string) => attribute.trim().length > 0),
      tooltipContent: group.tooltipContent,
      attributeLinkText: group.attributeLinkText,
      attributeLink: group.attributeLink,
      tooltipContentTabs: group.tooltipContentTabs,
    })) || [];

  const currentVariantIdx = useMemo<number>(() => {
    const currentVariantSKU = router.asPath.match(/\/p\/(?<productId>([^\/?]+))/)?.groups?.productId;
    return product?.variants.findIndex(({ sku }) => sku === currentVariantSKU) ?? 0;
  }, [product, router.asPath]);

  const variant = useMemo<Variant>(() => product?.variants[currentVariantIdx], [product, currentVariantIdx]);

  const { addItem, data: cart, getShippingMethods, getShippingMethodMapping } = useCart();
  const { data: wishlist } = useWishlist();
  // 🙈
  // feel free to add a map if there are later
  // more colors missing (or add to tailwind conf)
  const grayFix = (word: string) => (word === 'grey' ? 'gray' : word);

  // just two main features for now, colors and sizes.
  // we pick a unique list from the payload to build the
  // selector
  // Upon selecting a feature, color or size, we find the
  // selected variant from the list based on the selected
  // features..
  const colors = [
    ...new Map(
      product?.variants?.map((v: Variant) => [
        v.attributes?.color?.label,
        {
          name: v.attributes?.color?.label,
          key: v.attributes?.color?.key,
          bgColor: `bg-${grayFix(v.attributes?.color?.key)}-500`,
          selectedColor: `ring-${grayFix(v.attributes?.color?.key)}-500`,
        },
      ]),
    ).values(),
  ] as UIColor[];

  const sizes = [
    ...new Map(
      product?.variants?.map((v: Variant) => [v.attributes?.commonSize?.label, v.attributes?.commonSize]),
    ).values(),
  ] as UISize[];

  // this maps the entire payload to a component
  // friendly datastructure, so data and presentation
  // stay decoupled.
  // TODO: properly type

  const prod = useMemo<UIProduct>(
    () => ({
      productId: product?.productId,
      name: product?.name,
      _url: product?._url,
      // add variants as well, so we can select and filter
      variants: product?.variants,
      price: variant?.price,
      // rating: 4,
      images: variant?.images?.map((img: string, id: number) => ({
        id: `${variant?.sku}-${id}`,
        src: img,
        alt: variant?.sku,
      })),
      colors: colors.filter((color: UIColor) => color.key !== undefined),
      sizes: sizes.filter((size: UISize) => size !== undefined),
      isOnWishlist: !!wishlist?.lineItems?.find((lineItem) =>
        product?.variants.find((variant) => variant.sku === lineItem.variant.sku),
      ),
      description: `
      <p>${product?.description || ''}</p>
    `,

      details: [
        {
          name: 'Features',
          items: [
            variant?.attributes?.designer && `Designer: ${variant.attributes.designer.label}`,
            variant?.attributes?.gender && `Collection: ${variant.attributes.gender.label}`,
            variant?.attributes?.madeInItaly && `Made in Italy`,
          ],
        },
      ],
      categories: product?.categories,
    }),
    [product, variant, colors, sizes],
  );

  useEffect(() => {
    getShippingMethods()
      .then((data) => {
        setShippingMethods(data);
      })
      .catch((e) => {
        //do nothing
      });

    getShippingMethodMapping()
      .then((data) => {
        setShippingMethodMapping(data);
      })
      .catch((e) => {
        //do nothing
      });
  }, [getShippingMethodMapping, getShippingMethods]);

  useEffect(() => {
    if (!shippingMethodMapping || !variant) return;

    const map = shippingMethodMapping.attributeMapping?.find(
      (map) =>
        Object.keys(variant.attributes).includes(map.attributeIdentifier) &&
        `${map.attributeValue}`.toLowerCase() === `${variant.attributes[map.attributeIdentifier]}`.toLowerCase(),
    );

    setShippingMethodId(map ? map.shippingMethodIdentifier : shippingMethodMapping.defaultShippingMethod);

    if (shippingMethods && shippingMethodId) {
      const suitableShippingMethod = shippingMethods.find((method) => method.shippingMethodId === shippingMethodId);
      if (suitableShippingMethod) {
        setShippingRate(ShippingHandler.getShippingRate(suitableShippingMethod, 'DE'));
      }
    }
  }, [shippingMethods, shippingMethodMapping, variant, shippingMethodId]);

  useEffect(() => {
    if (variant) {
      new TagManager().viewItemEvent(product, variant, { pageVersion }).executePush();
    }
  }, []);

  const productSchema = useMemo(
    () => ({
      name: product?.name,
      image: variant?.images,
      description: product?.description,
      sku: variant?.sku,
      category: product?.categories?.[0]?.breadcrumbs?.map((category) => category.name).join(' > ') ?? '',
      brand: {
        '@type': 'Brand',
        name: `${variant?.attributes?.brand}`,
      },
      offers: {
        '@type': 'Offer',
        url:
          typeof window !== 'undefined'
            ? `${window?.location?.origin ?? ''}${router.asPath.split('/').slice(0, 3).join('/')}/${variant?.sku}`
            : `${router.asPath.split('/').slice(0, 3).join('/')}/${variant.sku}`,
        priceCurrency: variant?.price?.currencyCode,
        price: (variant?.price?.centAmount ?? 0) / Math.pow(10, variant?.price?.fractionDigits ?? 2),
        itemCondition: 'https://schema.org/RefurbishedCondition',
        availability: variant?.isOnStock ? 'https://schema.org/InStock' : 'https://schema.org/SoldOut',
        shippingDetails: {
          '@type': 'OfferShippingDetails',
          shippingRate: {
            '@type': 'MonetaryAmount',
            value: (shippingRate?.price?.centAmount ?? 0) / Math.pow(10, shippingRate?.price?.fractionDigits ?? 2),
            currency: shippingRate?.price?.currencyCode,
          },
          shippingDestination: [
            {
              '@type': 'DefinedRegion',
              addressCountry: 'DE',
            },
          ],
        },
      },
    }),
    [product, variant, colors, sizes, shippingRate],
  );

  const addScrollLock = () => {
    document.body.style.overflow = 'hidden';
    document.body.style.paddingLeft = '0px';
  };

  const closeOffcanvas = () => {
    setShowOffcanvas(false);
    document.body.removeAttribute('style');
  };

  const handleAddToCart = (variant: Variant, quantity = 1): Promise<void> => {
    new TagManager().addToCartEvent(product, variant, quantity, { pageVersion }).executePush();
    setShowOffcanvas(true);
    addScrollLock();
    return addItem(variant, 1);
  };

  const handleAddToWishList = () => {
    addToWishlist(variant.sku, 1);
  };

  const handleVariantIdxChange = (idx: number) => {
    const variant = product?.variants[idx];
    const url = `${router.asPath.split('/').slice(0, 3).join('/')}/${variant.sku}`;

    new TagManager().viewItemEvent(product, variant, { pageVersion }).executePush();
    router.replace(url, undefined, { shallow: true });
  };

  const goToProductPage = (_url: string) => {
    closeOffcanvas();
    router.push(_url);
  };

  const onCheckout = (e: FormEvent) => {
    e.preventDefault();
    closeOffcanvas();
    router.push('/checkout');
  };

  //For SSR render when going back
  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        router.replace(as, undefined);
        return false;
      }

      return true;
    });

    return () => router.beforePopState(() => true);
  }, []);

  if (!product || !variant || !prod) return <></>;

  const componentParameters = {
    product: prod,
    onAddToCart: handleAddToCart,
    variant: variant,
    onChangeVariantIdx: handleVariantIdxChange,
    onAddToWishlist: handleAddToWishList,
    quickBuyEnabled: data.quickBuyEnabled,
    benefits: benefits,
    opticalConditionModal: {
      enable: data.enableOpticalConditionModal,
      headline: data.modalHeadline,
      tabs: data.opticalConditionTabs,
    },
    attributeGroups: attributeGroups,
    mobile: data.mobile,
    tooltips: data.tooltips ?? [],
    imageFullScreenEnabled: data.imageFullScreenEnabled,
    seoProperties: {
      title: pageFolder.configuration?.seoTitle && translate(pageFolder.configuration?.seoTitle),
      description: pageFolder.configuration?.seoDescription && translate(pageFolder.configuration?.seoDescription),
      keywords: pageFolder.configuration?.seoKeywords && translate(pageFolder.configuration?.seoKeywords),
    },
    tsBadgeText: data.tsBadgeText,
    tsBadgeToggle: data.tsBadgeToggle,
    tabs: data.tabList,
  };

  const offcanvasBody: JSX.Element = (
    <div>
      <div className="flex items-center gap-2 py-3">
        <ListCheckIcon className="size-6 shrink-0" aria-hidden="true" />
        <div>
          {formatMessage({
            id: 'addedToCart',
            defaultMessage: 'The bike has been added to your shopping cart.',
          })}
        </div>
      </div>
      <div className="max-h-80 overflow-y-auto">
        <ul role="list" className="flex flex-auto flex-col-reverse overflow-y-auto pt-2">
          {cart?.lineItems?.map((lineItem, i) => (
            <li key={i} className="flex space-x-6 py-2">
              <LineItem lineItem={lineItem} goToProductPage={goToProductPage} />
            </li>
          ))}
        </ul>
      </div>
      <div className="pb-2 pt-4 text-sm">
        {formatMessage({
          id: 'notReserved',
          defaultMessage: 'Please note that our individual items in the shopping cart are not reserved for you.',
        })}
      </div>
      <div className="py-4">
        <button
          onClick={onCheckout}
          type="button"
          className="w-full items-center justify-center rounded-4xl bg-primary-200 px-8 py-3 text-md font-semibold text-neutral-950 transition duration-150 ease-out hover:bg-primary-100 focus:bg-primary-100 disabled:bg-neutral-200 disabled:text-neutral-500"
        >
          {formatMessage({ id: 'checkout', defaultMessage: 'To checkout' })}
        </button>
      </div>
      <BenefitsList benefits={benefits} classNames="pt-4 pb-2 flex flex-col gap-3" />
    </div>
  );

  return (
    <>
      <Head>
        <title>{translate(pageFolder.configuration?.seoTitle) ?? product.name}</title>
      </Head>
      {productSchema && <ProductStructuredData type="Product" data={productSchema} />}
      {pageVersion === 'v1' ? (
        <ProductDetailsV1 {...componentParameters} />
      ) : (
        <ProductDetails {...componentParameters} />
      )}
      <Offcanvas
        title={formatMessage({ id: 'offcanvasCartHeadline', defaultMessage: 'Your shopping basket' })}
        body={offcanvasBody}
        show={showOffcanvas}
        closeOverlay={() => closeOffcanvas()}
        mobileWidth="w-96 max-w-full"
        closeLabel="Warenkorb schließen"
      />
    </>
  );
}

export default ProductDetailsTastic;
