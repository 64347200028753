import React from 'react';
import HeartIcon from 'components/icons/heart';
import { useFormat } from 'helpers/hooks/useFormat';
import { Reference, ReferenceLink } from 'helpers/reference';

interface WishListButtonProps {
  wishlistItemCount?: number;
  wishlistLink?: Reference;
}

const WishListButton: React.FC<WishListButtonProps> = ({ wishlistItemCount, wishlistLink }) => {
  //i18n messages
  const { formatMessage: formatWishlistMessage } = useFormat({ name: 'wishlist' });

  return (
    <div className="mx-2 flow-root">
      <ReferenceLink
        ariaLabel="wishlist button"
        target={wishlistLink}
        className="group relative -m-2 flex items-center p-2"
      >
        <HeartIcon
          className="size-5 shrink-0 text-primary-400 group-hover:text-primary-500 dark:text-light-100 dark:group-hover:text-light-100"
          aria-hidden="true"
        />
        {wishlistItemCount > 0 && (
          <>
            <span className="absolute -right-1 -top-0 size-4 rounded-full bg-accent-400 hover:bg-accent-500">
              <span className="flex size-full items-center justify-center text-xs text-white group-hover:text-white">
                {wishlistItemCount}
              </span>
            </span>
            <span className="sr-only">
              {formatWishlistMessage({
                id: 'wishlist.items.in.view',
                defaultMessage: 'items in wishlist, view wishlist',
              })}
            </span>
          </>
        )}
      </ReferenceLink>
    </div>
  );
};

export default WishListButton;
