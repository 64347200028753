import React from 'react';
import type { Trustcard } from '@Types/trustedshops/Trustcard';

type TrustcardProps = {
  trustcard: Trustcard;
};

const Trustcard: React.FC<TrustcardProps> = ({ trustcard }) => {
  return (
    <div id="trustedShopsCheckout" style={{ display: 'none' }}>
      <span id="tsCheckoutOrderNr">{trustcard?.orderNumber}</span>
      <span id="tsCheckoutBuyerEmail">{trustcard?.buyerEmail}</span>
      <span id="tsCheckoutOrderAmount">{trustcard?.orderAmount}</span>
      <span id="tsCheckoutOrderCurrency">{trustcard?.orderCurrency}</span>
      <span id="tsCheckoutOrderPaymentType">{trustcard?.orderPaymentType}</span>
      <span id="tsCheckoutOrderEstDeliveryDate">{trustcard?.orderEstDeliveryDate}</span>
    </div>
  );
};

export default Trustcard;
