import React, { useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import { CartError } from '@Types/cart/CartError';
import Cart from 'components/revelo-ui/cart';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCart } from 'frontastic/provider';
import Spinner from '../../../components/commercetools-ui/spinner';
import useI18n from '../../../helpers/hooks/useI18n';

const CartTastic = ({ data, pageFolder }) => {
  const { t: translate } = useI18n();
  const { formatMessage } = useFormat({ name: 'cart' });
  const { data: cartList, removeItem, updateItemQuantity, refreshCart, validateCart } = useCart();
  const [cartErrors, setCartErrors] = useState<CartError[]>(undefined);
  const [isValidating, setIsValidating] = useState<boolean>(true);
  const cartValidated = useRef<boolean>(false);

  const editItemQuantity = (lineItemId: string, newQuantity: number) => updateItemQuantity(lineItemId, newQuantity);

  useEffect(() => {
    if (cartList?.cartId !== undefined && !cartValidated.current) {
      (async () => {
        await validateCart().then(async (response) => {
          cartValidated.current = true;
          if (!response.isValid) {
            await refreshCart();
          }
          setCartErrors(response.errors ?? []);
          setIsValidating(false);
        });
      })();
    }
  }, [cartList]);

  return (
    <>
      <Head>
        <title>
          {pageFolder.configuration?.seoTitle
            ? translate(pageFolder.configuration?.seoTitle)
            : formatMessage({
                id: 'checkout',
                defaultMessage: 'checkout',
              })}
        </title>
        <meta
          name="description"
          content={
            pageFolder.configuration?.seoDescription
              ? translate(pageFolder.configuration?.seoDescription)
              : formatMessage({
                  id: 'checkout',
                  defaultMessage: 'checkout',
                })
          }
        />
      </Head>
      {isValidating ? (
        <div className="flex items-stretch justify-center px-12 py-10">
          <Spinner />
        </div>
      ) : (
        <Cart
          cart={cartList}
          cartErrors={cartErrors}
          removeItem={removeItem}
          editItemQuantity={editItemQuantity}
          pageTitle={data.pageTitle}
          emptyStateImage={data.emptyStateImage}
          emptyStateTitle={data.emptyStateTitle}
          emptyStateSubtitle={data.emptyStateSubtitle}
          emptyStateCTALabel={data.emptyStateCTALabel}
          emptyStateCTALink={data.emptyStateCTALink}
          paymentLogos={data.paymentLogo}
          defaultShippingCountry={data.defaultShippingCountry}
          deliveryTime={data.deliveryTimeSnippet}
        />
      )}
    </>
  );
};

export default CartTastic;
